import { lazy, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { ReportingPageList } from '../../Components/NavBar/Reporting/ReportingPageList';
import { typedObjectKeys } from '../../Utilities/typeGuards/typedObjectKeys';
import { SettingsPageList } from '../../Components/NavBar/Settings/SettingsPageList';
import { AccountReports } from '../../Components/NavBar/Accounting/AccountingPageList';
import { SetupPageList } from '../../Components/NavBar/Setup/SetupPageList';
import { StatusPagePageList } from '../../Components/NavBar/StatusPage/StatusPagePageList';
import { AgentsPageList } from '../../Components/NavBar/Agents/AgentsPageList';
import { RedirectRoute } from '../RedirectRoute';

const DashboardPage = lazy(
  () => import('../../Components/NavBar/Dashboard/DashboardPage')
);
const StatusPage = lazy(
  () => import('../../Components/NavBar/StatusPage/StatusPage')
);
const ReportingPage = lazy(
  () => import('../../Components/NavBar/Reporting/ReportingPage')
);
const SetupPage = lazy(() => import('../../Components/NavBar/Setup/SetupPage'));
const AgentPage = lazy(
  () => import('../../Components/NavBar/Agents/AgentPage')
);
const AccountingPage = lazy(
  () => import('../../Components/NavBar/Accounting/AccountingPage')
);
const SettingsPage = lazy(
  () => import('../../Components/NavBar/Settings/SettingsPage')
);

export const usePrivateRoutes = (isLoadedConditional: boolean) => {
  const pageList = useMemo(() => {
    return {
      reporting: ReportingPageList(),
      settings: SettingsPageList(),
      account: AccountReports(),
      setup: SetupPageList(),
      status: StatusPagePageList(),
      agents: AgentsPageList()
    };
  }, []);

  // This is the list of private routes that are only available to logged in users.
  // The routes are rendered in the Outlet component (inside MainComponent).
  return [
    <Route key="Dashboard" path="/Dashboard" element={<DashboardPage />} />,
    <Route key="Reporting" path="/Reporting" element={<ReportingPage />}>
      {typedObjectKeys(pageList.reporting).map((pageKey) => {
        const page = pageList.reporting[pageKey];
        const path = page?.hasSubPaths ? `${pageKey}/*` : pageKey;

        return (
          <Route
            key={`Reporting-${pageKey}`}
            path={path}
            element={page?.content}
          />
        );
      })}
      <Route
        path="/Reporting/*"
        element={<RedirectRoute isLoadedConditional={isLoadedConditional} />}
      />
    </Route>,
    <Route key="Status" path="/Status" element={<StatusPage />}>
      {typedObjectKeys(pageList.status).map((pageKey) => {
        return (
          <Route
            key={`Status-${pageKey}`}
            path={pageKey}
            element={pageList.status[pageKey]?.content}
          />
        );
      })}
      <Route
        path="/Status/*"
        element={<RedirectRoute isLoadedConditional={isLoadedConditional} />}
      />
    </Route>,
    <Route key="Agents" path="/Agents" element={<AgentPage />}>
      {typedObjectKeys(pageList.agents).map((pageKey) => {
        return (
          <Route
            key={`Agents-${pageKey}`}
            path={pageKey}
            element={pageList.agents[pageKey]?.content}
          />
        );
      })}
      <Route
        path="/Agents/*"
        element={<RedirectRoute isLoadedConditional={isLoadedConditional} />}
      />
    </Route>,
    <Route key="Accounting" path="/Accounting" element={<AccountingPage />}>
      {typedObjectKeys(pageList.account).map((pageKey) => {
        return (
          <Route
            key={`Account-${pageKey}`}
            path={pageKey}
            element={pageList.account[pageKey]?.content}
          />
        );
      })}
      <Route
        path="/Accounting/*"
        element={<RedirectRoute isLoadedConditional={isLoadedConditional} />}
      />
    </Route>,
    <Route key="Settings" path="/Settings" element={<SettingsPage />}>
      {typedObjectKeys(pageList.settings).map((pageKey) => {
        return (
          <Route
            key={`Settings-${pageKey}`}
            path={pageKey}
            element={pageList.settings[pageKey]?.content}
          />
        );
      })}
      <Route
        path="/Settings/*"
        element={<RedirectRoute isLoadedConditional={isLoadedConditional} />}
      />
    </Route>,
    <Route key="Setup" path="/Setup" element={<SetupPage />}>
      {typedObjectKeys(pageList.setup).map((pageKey) => {
        return (
          <Route
            key={`Setup-${pageKey}`}
            path={pageKey}
            element={pageList.setup[pageKey]?.content}
          />
        );
      })}
      <Route
        path="/Setup/*"
        element={<RedirectRoute isLoadedConditional={isLoadedConditional} />}
      />
    </Route>
  ];
};
