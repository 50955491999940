import { getSellers } from '../../../../common/commonRequests';
import { FORM_DATA_OPTIONS } from '../../../../common/dropdownConstants';
import {
  checkAffiliate,
  checkSuperAffiliate,
  checkAmb,
  checkIsRemarketing
} from '../../../../PermissionsWrappers/permissionChecks';

const siteOptions = [
  { value: 0, display: 'Select a Vertical' },
  { value: '2', display: 'Auto' },
  { value: '4', display: 'Home' },
  { value: '1', display: 'Life' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions,
    value: '0',
    inputWidth: 3
  },
  seller: {
    name: 'seller',
    type: 'select',
    label: 'Seller',
    options: (dependency) => getSellers(dependency),
    value: 'all',
    inputWidth: 3,
    dependencyName: 'site'
  },
  partial: {
    name: 'partial',
    type: 'select',
    label: 'Form Type',
    options: () => FORM_DATA_OPTIONS,
    hidden:
      checkAffiliate() ||
      checkSuperAffiliate() ||
      checkIsRemarketing() ||
      checkAmb()
  }
});
