import { affiliateID, siteOptions } from '../../../common/commonRequests';

export const filters = () => ({
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all'
  },
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  }
});
