import moment from 'moment';
import { getFromLocalStorage } from '../Utilities/saveStateHelper';

export const PREDEFINED_RANGES_NAMES = {
  today: 'today', // eslint-disable-line no-use-before-define
  yesterday: 'yesterday',
  daysAgo2: 'two_days_ago',
  last7Days: 'last_seven_days',
  last14Days: 'last_fourteen_days',
  last30Days: 'last_thirty_days',
  thisMonth: 'this_month',
  lastMonth: 'last_month',
  allTime: 'all_time',
  weeks4: 'weeks4',
  weeks6: 'weeks6',
  weeks12: 'weeks12',
  weeks24: 'weeks24'
};

export const date = new Date();
export const estTimeZoneDate = new Intl.DateTimeFormat('en-US', {
  timeZone: 'America/New_York'
}).format(date);
export const currentTimeZoneDate = new Intl.DateTimeFormat().format(date);

export const getEstDate = (days) => {
  if (moment(estTimeZoneDate).isAfter(currentTimeZoneDate)) {
    return moment()
      .startOf('day')
      .add(days + 1, 'days')
      .format('MM/DD/YYYY');
  }
  if (moment(estTimeZoneDate).isBefore(currentTimeZoneDate)) {
    return moment()
      .startOf('day')
      .add(days - 1, 'days')
      .format('MM/DD/YYYY');
  }
  return moment().startOf('day').add(days, 'days').format('MM/DD/YYYY');
};

const getToday = () => {
  return new Date(getEstDate(0));
};

const getLastDaysAgo = (days) => {
  return new Date(moment().add(days, 'days').format('MM/DD/YYYY'));
};

const getYesterday = () => {
  return new Date(getEstDate(-1));
};

const thisMonth = new Date(moment().startOf('month').format('MM/DD/YYYY'));
const startOfLastMonth = new Date(
  moment().startOf('month').subtract(1, 'months').format('MM/DD/YYYY')
);
const endOfLastMonth = new Date(
  moment()
    .startOf('month')
    .subtract(1, 'months')
    .endOf('month')
    .format('MM/DD/YYYY')
);

const weeks4 = new Date(
  moment().subtract(4, 'w').isoWeekday(7).format('MM/DD/YYYY')
);
const weeks6 = new Date(
  moment().subtract(6, 'w').isoWeekday(7).format('MM/DD/YYYY')
);
const weeks12 = new Date(
  moment().subtract(12, 'w').isoWeekday(7).format('MM/DD/YYYY')
);
const weeks24 = new Date(
  moment().subtract(24, 'w').isoWeekday(7).format('MM/DD/YYYY')
);

export const PREDEFINED_RANGES = {
  [PREDEFINED_RANGES_NAMES.today]: {
    label: 'Today',
    range: () => ({
      startDate: getToday(),
      endDate: getToday(),
      range: PREDEFINED_RANGES_NAMES.today
    }),
    isSelected() {
      return PREDEFINED_RANGES_NAMES.today === getFromLocalStorage('dateRange');
    }
  },
  [PREDEFINED_RANGES_NAMES.weeks4]: {
    label: '4 Weeks',
    range: () => ({
      startDate: weeks4,
      endDate: getToday(),
      range: PREDEFINED_RANGES_NAMES.weeks4
    })
  },
  [PREDEFINED_RANGES_NAMES.weeks6]: {
    label: '6 Weeks',
    range: () => ({
      startDate: weeks6,
      endDate: getToday(),
      range: PREDEFINED_RANGES_NAMES.weeks6
    })
  },
  [PREDEFINED_RANGES_NAMES.weeks12]: {
    label: '12 Weeks',
    range: () => ({
      startDate: weeks12,
      endDate: getToday(),
      range: PREDEFINED_RANGES_NAMES.weeks12
    })
  },
  [PREDEFINED_RANGES_NAMES.weeks24]: {
    label: '24 Weeks',
    range: () => ({
      startDate: weeks24,
      endDate: getToday(),
      range: PREDEFINED_RANGES_NAMES.weeks24
    })
  },
  [PREDEFINED_RANGES_NAMES.yesterday]: {
    label: 'Yesterday',
    range: () => ({
      startDate: getYesterday(),
      endDate: getYesterday(),
      range: PREDEFINED_RANGES_NAMES.yesterday
    }),
    isSelected() {
      return (
        PREDEFINED_RANGES_NAMES.yesterday === getFromLocalStorage('dateRange')
      );
    }
  },
  [PREDEFINED_RANGES_NAMES.daysAgo2]: {
    label: '2 Days Ago',
    range: () => ({
      startDate: getLastDaysAgo(-2),
      endDate: getLastDaysAgo(-2),
      range: PREDEFINED_RANGES_NAMES.daysAgo2
    }),
    isSelected() {
      return (
        PREDEFINED_RANGES_NAMES.daysAgo2 === getFromLocalStorage('dateRange')
      );
    }
  },
  [PREDEFINED_RANGES_NAMES.last7Days]: {
    label: 'Last 7 Days',
    range: () => ({
      startDate: getLastDaysAgo(-7),
      endDate: getYesterday(),
      range: PREDEFINED_RANGES_NAMES.last7Days
    }),
    isSelected() {
      return (
        PREDEFINED_RANGES_NAMES.last7Days === getFromLocalStorage('dateRange')
      );
    }
  },
  [PREDEFINED_RANGES_NAMES.last14Days]: {
    label: 'Last 14 Days',
    range: () => ({
      startDate: getLastDaysAgo(-14),
      endDate: getYesterday(),
      range: PREDEFINED_RANGES_NAMES.last14Days
    }),
    isSelected() {
      return (
        PREDEFINED_RANGES_NAMES.last14Days === getFromLocalStorage('dateRange')
      );
    }
  },
  [PREDEFINED_RANGES_NAMES.last30Days]: {
    label: 'Last 30 Days',
    range: () => ({
      startDate: getLastDaysAgo(-30),
      endDate: getYesterday(),
      range: PREDEFINED_RANGES_NAMES.last30Days
    }),
    isSelected() {
      return (
        PREDEFINED_RANGES_NAMES.last30Days === getFromLocalStorage('dateRange')
      );
    }
  },
  [PREDEFINED_RANGES_NAMES.thisMonth]: {
    label: 'This Month',
    range: () => ({
      startDate: thisMonth,
      endDate: getToday(),
      range: PREDEFINED_RANGES_NAMES.thisMonth
    }),
    isSelected() {
      return (
        PREDEFINED_RANGES_NAMES.thisMonth === getFromLocalStorage('dateRange')
      );
    }
  },
  [PREDEFINED_RANGES_NAMES.lastMonth]: {
    label: 'Last Month',
    range: () => ({
      startDate: startOfLastMonth,
      endDate: endOfLastMonth,
      range: PREDEFINED_RANGES_NAMES.lastMonth
    }),
    isSelected() {
      return (
        PREDEFINED_RANGES_NAMES.lastMonth === getFromLocalStorage('dateRange')
      );
    }
  },
  [PREDEFINED_RANGES_NAMES.allTime]: {
    label: 'All Time',
    range: () => ({
      range: PREDEFINED_RANGES_NAMES.allTime,
      startDate: moment('1/1/2017'),
      endDate: moment().endOf('day')
    }),
    isSelected() {
      return (
        PREDEFINED_RANGES_NAMES.allTime === getFromLocalStorage('dateRange')
      );
    }
  }
};

export const WEEK_DAYS_OPTIONS = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Peak',
    value: 'peak'
  },
  {
    label: 'Off-Peak',
    value: 'off_peak'
  }
];
