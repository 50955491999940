/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { colors } from '../../../Utilities/LenoxColors';

export const submitButtonStyles = () => ({
  submitButton: {
    backgroundColor: colors.lenoxSuccess1,
    color: colors.lenoxLight1,
    marginTop: '3rem',
    marginBottom: '3rem',
    '@media (max-width: 768px)': {
      marginTop: '1rem',
      marginBottom: '0rem'
    }
  },
  resetButton: {
    backgroundColor: colors.lightGray,
    color: colors.lenoxLight1,
    marginTop: '3rem',
    marginRight: '1rem',
    marginBottom: '3rem',
    '@media (max-width: 768px)': {
      marginTop: '1rem',
      marginBottom: '0rem'
    }
  },
  customButton: {
    backgroundColor: colors.lenoxSuccess1,
    color: colors.lenoxLight1,
    marginTop: '3rem',
    marginRight: '1rem',
    marginBottom: '3rem',
    '@media (max-width: 768px)': {
      marginTop: '1rem',
      marginBottom: '0rem'
    }
  },
  noMargin: {
    margin: 0
  }
});

class SubmitButton extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleReset: PropTypes.func,
    handleCustomAction: PropTypes.func,
    submitting: PropTypes.bool,
    content: PropTypes.string,
    contentReset: PropTypes.string
  };

  static defaultProps = {
    submitting: false,
    content: 'Submit',
    contentReset: 'Reset'
  };

  submitting = () => <CircularProgress color="inherit" size={25} />;

  renderButtonContent = (content) => {
    if (this.props.submitting) {
      return this.submitting();
    }
    return content;
  };

  renderSubmitButton = () => (
    <Tooltip placement="top" title={this.props.tooltip || ''}>
      <Button
        id="submitButton"
        variant="contained"
        color="inherit"
        className={classNames(
          this.props.classes.submitButton,
          this.props.noMargin && this.props.classes.noMargin
        )}
        onClick={this.props.handleSubmit}
        disabled={this.props.submitting || this.props.disabled}
        data-testId={`${this.props.testId}_SubmitButton`}
      >
        {this.renderButtonContent(this.props.content)}
      </Button>
    </Tooltip>
  );

  renderCustomActionButton = () =>
    this.props.handleCustomAction && (
      <Tooltip placement="top" title={this.props.customActionTooltip || ''}>
        <Button
          id="submitButton"
          variant="contained"
          color="inherit"
          className={classNames(this.props.classes.customButton)}
          onClick={this.props.handleCustomAction}
          disabled={this.props.submitting || this.props.disabled}
          testId={`${this.props.testId}_CustomActionButton`}
        >
          {this.renderButtonContent(this.props.customActionContent)}
        </Button>
      </Tooltip>
    );

  renderResetButton = () =>
    this.props.handleReset && (
      <Button
        id="resetButton"
        variant="contained"
        color="inherit"
        className={this.props.classes.resetButton}
        onClick={this.props.handleReset}
        disabled={this.props.submitting || this.props.disabled}
      >
        {this.renderButtonContent(this.props.contentReset)}
      </Button>
    );

  render() {
    const { formValues, hideSubmit, hideReset, hideCustomAction } = this.props;
    let bottomFormComponents = [];
    if (formValues) {
      const keys = Object.keys(formValues);
      bottomFormComponents = keys
        .map((fieldKey) => formValues[fieldKey])
        .filter((field) => field.type === 'underForm');
    }
    return (
      <>
        {bottomFormComponents &&
          bottomFormComponents.length > 0 &&
          bottomFormComponents[0].render &&
          bottomFormComponents[0].render()}
        {!hideReset && this.renderResetButton()}
        {!hideCustomAction && this.renderCustomActionButton()}
        {!hideSubmit && this.renderSubmitButton()}
      </>
    );
  }
}

export default withStyles(SubmitButton, submitButtonStyles);
