const DEV_SERVER = process.env.REACT_APP_LENOX_DEV_SERVER || '';

const hosts = ['', '2'];
let baseURL = () =>
  `https://api${hosts[Math.floor(Math.random() * hosts.length)]}.useotto.tech`;

if (DEV_SERVER) {
  baseURL = () => DEV_SERVER;
}

export const getDataUrl = () => ({
  permissions: `${baseURL()}/api/v2/permissions.php`,
  reports: `${baseURL()}/api/v2/reports.php`,
  providers: `${baseURL()}/api/v2/providers.php`,
  buyers: `${baseURL()}/api/v2/buyers.php`,
  buyerNotes: `${baseURL()}/api/v2/buyerNotes.php`,
  buyersReturnsBreakdown: `${baseURL()}/api/v2/buyersReturns.php`,
  login: `${baseURL()}/api/v2/auth.php`,
  agents: `${baseURL()}/api/v2/agents.php`,
  creatives: `${baseURL()}/api/v2/creatives.php`,
  bandits: `${baseURL()}/api/v2/bandits.php`,
  affiliates: `${baseURL()}/api/v2/affiliates.php`,
  sites: `${baseURL()}/api/v2/sites.php`,
  affiliateSpend: `${baseURL()}/api/v2/affiliateSpend.php`,
  affiliateSpendS1: `${baseURL()}/api/v2/affiliateSpendS1.php`,
  revenueAdjustments: `${baseURL()}/api/v2/revenue_adjustments.php`,
  buyerAffidConfig: `${baseURL()}/api/v2/buyerAffidConfig.php`,
  dashboard: `${baseURL()}/api/v2/dashboard.php`,
  pixels: `${baseURL()}/api/v2/pixels.php`,
  spendData: `${baseURL()}/api/v2/spendData.php`,
  users: `${baseURL()}/api/v2/users.php`,
  validatePhoneNumber: `${baseURL()}/api/v2/validatePhoneNumber.php`,
  leads: `${baseURL()}/api/v2/leads.php`,
  leadData: `${baseURL()}/api/v2/leadData.php`,
  fileUpload: `${baseURL()}/api/v2/fileUpload.php`,
  statesDenylist: `${baseURL()}/api/v2/statesDenylist.php`,
  public: `${baseURL()}/api/v2/public.php`,
  billing: `${baseURL()}/api/v2/billing.php`,
  uaDenylist: `${baseURL()}/api/v2/uaDenylist.php`,
  emailDenylist: `${baseURL()}/api/v2/emailDenylist.php`,
  throttle: `${baseURL()}/api/v2/throttle.php`,
  five9: `${baseURL()}/api/v2/five9_status.php`,
  constraints: `${baseURL()}/api/v2/constraints.php`,
  variants: `${baseURL()}/api/v2/variants.php`,
  tableSettings: `${baseURL()}/api/v2/dataGridCustomization.php`,
  supportEmail: `${baseURL()}/api/v2/abandonedEmail.php`,
  ty: `${baseURL()}/api/v2/ty.php`,
  push_key: `${baseURL()}/api/v3/external/push_key.php`,
  affiliate_signup: `${baseURL()}/api/v2/external/affiliate_signup.php`,
  affiliate_signup_internal: `${baseURL()}/api/v2/affiliate_signup.php`,
  affiliateBuyers: `${baseURL()}/api/v2/affiliateBuyerIds.php`,
  emailDomainMapping: `${baseURL()}/api/v2/emailDomainMapping.php`,
  clickPurchase: `${baseURL()}/api/v2/clickPurchase.php`,
  buyerAffiliates: `${baseURL()}/api/v2/buyerAffiliates.php`,
  sellers: `${baseURL()}/api/v2/sellers.php`,
  forms: `${baseURL()}/api/v2/forms.php`,
  carriers: `${baseURL()}/api/v2/carriers.php`,
  closingMonth: `${baseURL()}/api/v2/closingMonth.php`,
  unsub: `${baseURL()}/external/general/unsub.php`,
  campaign: `${baseURL()}/api/v2/campaign.php`
});
