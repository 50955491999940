/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Getter, Plugin } from '@devexpress/dx-react-core';
import { calculateTotals } from './calculateGrandTotals';
import { formatAffiliateValue } from '../../Utilities/getAffiliateIDFromName';

class GrandTotalsState extends PureComponent {
  state = {
    totals: { timestamp: new Date().getTime() }
  };

  setTotalsCallback = (totals) => {
    const { affiliateID } = this.props;
    if (affiliateID && affiliateID !== 'all' && !Number.isNaN(affiliateID)) {
      totals.affiliate_id = affiliateID;
    }
    this.setState({ totals });
  };

  getTotals = (time, rows) => {
    const totals = { timestamp: this.state.totals.timestamp };
    const { totalsMapping } = this.props;
    if (time > totals.timestamp) {
      this.setState({ totals: { timestamp: new Date().getTime() } }, () =>
        calculateTotals(totals, rows, totalsMapping, this.setTotalsCallback)
      );
    }
  };

  renderTotalsRows = ({ rows }) => {
    const now = Date.now();
    this.getTotals(now, rows);
    return [].concat(
      [
        {
          key: 'totals',
          type: 'totals'
        }
      ],
      rows
    );
  };

  render() {
    const { totals } = this.state;

    return (
      <Plugin name="GrandTotalsState">
        <Getter name="tableBodyRows" computed={this.renderTotalsRows} />
        <Getter name="grandTotals" value={totals} />
      </Plugin>
    );
  }
}

export default connect((state) => ({
  affiliateID: formatAffiliateValue(state.filters.affiliateID)
}))(GrandTotalsState);
