/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { isEqual, debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  AccessTime,
  InfoOutlined,
  List,
  Edit as EditIcon
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { colors } from '../../../../Utilities/LenoxColors';
import Table from '../../../TableContainer/Table';
import BarChart from '../../../Charts/BarChart';
import PieChart from '../../../Charts/PieChart';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import {
  getColumnCompare,
  convertToFormattedNumber
} from '../../../TableContainer/TableUtilities/columnFormatter';
import { ROUTE_NAMES } from '../../../NavBar/NavigationContainer';
import { getBuyerReport } from '../../requests';
import { getTableDataWithMostUpdated } from '../../../TableContainer/TableUtilities/buildTableRequest';
import {
  renderDefaultCell,
  renderCurrencyCell,
  renderPercentCell,
  renderNumberCell,
  columnTooltip
} from '../../../TableContainer/TableUtilities/defaultCells';
import { formatAffiliateValue } from '../../../Utilities/getAffiliateIDFromName';
import ChartsWrapper from '../../../Charts/ChartsWrapper';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import Toggle from '../../../FormContainer/FormComponents/Toggle';
import ActionDrawer from '../../../PageContainer/ActionDrawer';
import EditBuyer from '../BuyerSetup/form/render/EditBuyer';
import EditBuyerNotes from '../BuyerSetup/form/render/EditBuyerNotes';
import CSVButton from '../../../TableContainer/TableUtilities/CSVButton';
import { getDataSorted, formatXAxis } from '../../../common/utilities';
import { isMobileDevice } from '../../../common/isMobileDevice';
import {
  getFromLocalStorage,
  saveToLocalStorage
} from '../../../Utilities/saveStateHelper';
import { renderVisibilityCheckbox } from '../../../common/commonComponents';
import Dialog from '../../../Dialog';
import { TextField } from '../../../FormContainer/FormComponents';
import { renderReportLink } from '../../../TableContainer/TableUtilities/RenderReportLink';
import {
  handleChange as updatePage,
  updateBreadCrumbs
} from '../../../PageContainer/actions';
import {
  handleGetDataChange,
  handleLastUpdatedChange
} from '../../../lenox/actions';
import { handleChange as updateFilters } from '../../../Filters/actions/actions';
import SkippedReasons from './SkippedReasons';
import LeadReturns from '../LeadReturns/render/LeadReturns';
import ReturnsBreakdown from './ReturnsBreakdown';
import BuyerAffiliatesBreakdown from './BuyerAffiliatesBreakdown';
import { usePrevious } from '../../../../Utilities/hooks/usePrevious';
import { logError } from '../../../../Utilities/logError';

const columns = [
  { name: 'buyer', title: 'Buyers', width: 350 },
  { name: 'edit', title: 'Edit', width: 175 },
  { name: 'breakdown', title: 'Breakdown', width: 120 },
  {
    name: 'revenue_final',
    title: columnTooltip(
      'Estimated Revenue',
      'Estimated Revenue is the total purchased for this date.'
    ),
    csvTitle: 'Estimated Revenue',
    width: 170,
    compare: 'priority'
  },
  {
    name: 'revenue_reported',
    title: 'Reported Revenue',
    width: 150,
    compare: 'priority'
  },
  { name: 'leads', title: 'Attempts', width: 120, compare: 'priority' },
  {
    name: 'skipped_leads',
    title: 'Skipped Leads',
    width: 140,
    compare: 'priority'
  },
  { name: 'pings', title: 'Pings', width: 120, compare: 'priority' },
  {
    name: 'pings_rejected',
    title: 'Pings Rejected',
    width: 130,
    compare: 'priority'
  },
  {
    name: 'bid_average_by_pings',
    title: 'Bid Average By Pings',
    width: 160,
    compare: 'priority'
  },
  {
    name: 'bid_threshold_rejects',
    title: 'Bids Under Threshold',
    width: 160,
    compare: 'priority'
  },
  { name: 'posted', title: 'Posted', width: 120, compare: 'priority' },
  { name: 'sold', title: 'Sold', width: 120, compare: 'priority' },
  { name: 'apv', title: 'APV', width: 120, compare: 'priority' },
  { name: 'asv', title: 'ABV', width: 120, compare: 'priority' },
  { name: 'conversion', title: 'Sold %', width: 120, compare: 'priority' },
  {
    name: 'unique_users_returns',
    title: 'Returns',
    width: 120,
    compare: 'priority'
  },
  {
    name: 'return_percent',
    title: 'Return %',
    width: 120,
    compare: 'priority'
  },
  { name: 'status', title: 'Active', width: 100 }
];

const breakdownColumns = [
  { name: 'affiliate_name', title: 'Affiliate', width: 400 },
  {
    name: 'revenue_final',
    title: columnTooltip(
      'Estimated Revenue',
      'Estimated Revenue is the total purchased for this date.'
    ),
    width: 170,
    compare: 'priority'
  },
  { name: 'leads', title: 'Attempts', width: 120, compare: 'priority' },
  { name: 'posted', title: 'Posted', width: 120, compare: 'priority' },
  { name: 'sold', title: 'Sold', width: 120, compare: 'priority' },
  { name: 'apv', title: 'APV', width: 120, compare: 'priority' },
  { name: 'conversion', title: 'Sold %', width: 120, compare: 'priority' }
];

const totalMapping = (props) => ({
  buyer: { type: 'header' },
  revenue_reported: { type: props.time === 'all' ? 'currency' : 'n/a' },
  revenue_final: { type: 'currency' },
  revenue_total: { type: 'currency' },
  leads: { type: 'number' },
  skipped_leads: { type: props.time === 'all' ? 'number' : 'n/a' },
  pings: { type: 'number' },
  pings_rejected: { type: 'number' },
  bid_average_by_pings: {
    type: 'average_currency',
    dividend: (rows) =>
      (rows ?? []).reduce((total, row) => {
        if (!row) {
          return 0;
        }

        return total + parseFloat(row.sum_bid);
      }, 0),
    divisor: (rows) =>
      (rows ?? []).reduce((total, row) => {
        if (!row) {
          return 0;
        }
        let pings = parseInt(row.pings, 10);
        if (Number.isNaN(pings)) {
          pings = 0;
        }

        return total + pings;
      }, 0)
  },
  bid_threshold_rejects: { type: props.time === 'all' ? 'number' : 'n/a' },
  posted: { type: 'number' },
  sold: { type: 'number' },
  apv: {
    type: 'average_currency',
    dividend: 'revenue_total',
    divisor: 'posted'
  },
  asv: { type: 'average_currency', dividend: 'revenue_total', divisor: 'sold' },
  conversion: { type: 'percent', dividend: 'sold', divisor: 'posted' },
  unique_users_returns: {
    type: 'drawerContainer',
    render: (
      <ActionDrawer
        inline
        drawerTitle="Total Returns Affiliate Breakdown"
        content={<ReturnsBreakdown buyerName="all" />}
      />
    )
  },
  return_percent: {
    type: 'percent',
    dividend: 'unique_users_returns',
    divisor: 'sold'
  },
  status: false,
  edit: false,
  breakdown: false
});

const buildTableParams = (action, props) => ({
  action,
  start_date: moment(props.startDate).format('YYYY-MM-DD'),
  end_date: moment(props.endDate).format('YYYY-MM-DD'),
  date_range: props.dateRange,
  peak: props.peak,
  site: props.site,
  affiliate: formatAffiliateValue(props.affiliateID),
  legacy: props.legacy,
  traffic_source: props.trafficSource,
  buyer_type: props.excludeBuyerType,
  time: props.time
});

export const expectArrayData = (data) =>
  data && typeof data === 'object' ? data : [];

const BuyerReport = (props) => {
  const [rows, setRows] = useState([]);
  const [defaultFilterValue, setDefaultFilterValue] = useState([]);
  const [hide_rows, setHiden_Rows] = useState(
    getFromLocalStorage('buyer_report_hide_rows', true)
  );
  const [isLoading, onToggleLoading] = useState(true);
  const [pauseReason, setPauseReason] = useState('');
  const [isPauseReasonPopupShown, onTogglePauseReasonPopup] = useState(false);
  const [pauseReasonError, setPauseReasonError] = useState('');
  const [pausedRow, setPausedRow] = useState('');
  const [pausedName, setPausedName] = useState('');
  const [isLoadingError, onToggleLoadingError] = useState(false);
  const [filters, onFiltersChange] = useState([]);
  const previousProps = usePrevious(buildTableParams('getBuyerReport', props));
  const navigate = useNavigate();
  const location = window.location.pathname;

  const requestTime = useRef();

  const buyerReport = useRef(null);
  const getRequestTime = () => requestTime.current.toString();

  useEffect(() => {
    const defaultFilterValue = getFromLocalStorage('buyer_reportbuyer');
    setDefaultFilterValue([defaultFilterValue]);
    getData();

    props.handleGetDataChange(getData);
    return () => {
      props.handleLastUpdatedChange('');
      saveToLocalStorage(`buyer_reportbuyer`, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEqual(buildTableParams('getBuyerReport', props), previousProps)) {
      onToggleLoading(true);
      getData();
      props.handleLastUpdatedChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, previousProps]);

  function getData() {
    const newRequestTime = new Date().getTime() / 1000;
    requestTime.current = newRequestTime;

    getTableDataWithMostUpdated(
      buildTableParams('getBuyerReport', props),
      getBuyerReport,
      (values) => {
        if (newRequestTime.toString() === getRequestTime()) {
          const { rows = [], loading, lastUpdated } = values ?? {};
          setRows(rows);
          onToggleLoading(loading);
          props.handleLastUpdatedChange(lastUpdated);
        }
      }
    ).catch((error) => {
      logError(error);
      setRows([]);
      onToggleLoading(false);
      onToggleLoadingError(true);
    });
  }

  function updateRow(row, name, value, callback = () => {}) {
    const result = [...(rows ?? [])];
    result.forEach(({ buyer_id }, index) => {
      if (row.buyer_id === buyer_id) {
        result[index][name] = value;
      }
    });
    setRows(result);
    callback && callback();
  }

  function onUpdateFiltersWithDebounce(value) {
    debounce(() => {
      onFiltersChange(value);
    }, 250);
    saveToLocalStorage('buyer_reportbuyer', value);
    setDefaultFilterValue([value]);
  }

  function handleToggle(row, name, value) {
    updateRow(row, name, value);
    if (!value) {
      openPauseReasonPopup(row, name);
    } else {
      updateStatusValue(row, name, value);
    }
  }

  function openPauseReasonPopup(row, name) {
    onTogglePauseReasonPopup(true);
    setPausedName(name);
    setPausedRow(row);
  }

  function closePauseReasonPopup() {
    updateRow(pausedRow, pausedName, true, cleanPauseData);
  }

  function cleanPauseData() {
    onTogglePauseReasonPopup(false);
    setPausedName('');
    setPausedRow('');
    setPauseReasonError('');
    setPauseReason('');
  }

  async function updateStatusValue(row, name, value) {
    onTogglePauseReasonPopup(false);

    const { data } = await NetworkRequest('buyers', {
      action: 'toggleBuyerStatus',
      status: value,
      id: row.buyer_id,
      reason: pauseReason
    });

    if (!data.success) {
      updateRow(row, name, !value, cleanPauseData);
    } else {
      onToggleLoading(true);
      getData();
      cleanPauseData();
    }
  }

  function handleHideShow() {
    setHiden_Rows(!hide_rows);
    saveToLocalStorage(`buyer_report_hide_rows`, hide_rows);
  }

  function redirectReport(page, buyer) {
    saveToLocalStorage('hourlyBuyerTotalssite', props.site);
    saveToLocalStorage('hourlyBuyerTotalsbuyer', buyer);
    props.updatePage('selectedPage', page);
    navigate(`/${ROUTE_NAMES.Reporting}/${page}`);
  }

  const renderConfirmDialog = () => (
    <Dialog
      title="Pause Buyer"
      open={!!isPauseReasonPopupShown}
      onRequestSave={async () => {
        if (pauseReason.trim() === '') {
          setPauseReasonError('Please enter valid value.');
          return true;
        }
        await updateStatusValue(pausedRow, pausedName, false);
      }}
      onRequestClose={closePauseReasonPopup}
      type="confirm"
      saveButtonText="Pause"
    >
      <TextField
        name="pauseReason"
        label="Reason to Pause"
        fullwidth="true"
        error={!!pauseReasonError}
        message={pauseReasonError}
        value={pauseReason}
        onChange={(name, value) => {
          setPauseReason(value);
          setPauseReasonError('');
        }}
      />
    </Dialog>
  );

  const renderToggle = (row, columnName) =>
    row.status !== undefined && (
      <Toggle
        value={row[columnName] === true || row[columnName].toString() === '1'}
        name={columnName}
        status={row.status}
        disabled={Number(row.status) === 2 || row.disable_status}
        onChange={(name, value) => handleToggle(row, name, value)}
        tooltipTitle={row?.disable_status_message || ''}
      />
    );

  const renderEditButton = (row) =>
    row.status !== undefined && (
      <div style={{ maxWidth: '10rem' }}>
        <ActionDrawer
          editIconButton
          drawerTitle="Edit Settings"
          content={
            <EditBuyer row={row} onChange={updateRow} site={props.site} />
          }
        />
      </div>
    );

  const renderNoEditButton = (row) =>
    row.status !== undefined && (
      <div style={{ maxWidth: '10rem' }}>
        <Tooltip
          title={`Settings disabled use buyer ${row.settings_buyer} for settings`}
        >
          <span>
            <IconButton disabled size="large">
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );

  const renderNoteTooltip = (note) => (
    <div style={{ fontSize: 16, lineHeight: '20px' }}>{note}</div>
  );

  const renderReportNotesTooltip = (row) =>
    row.status !== undefined && (
      <IconButton
        disabled={row.note === null || row.note === undefined}
        disableRipple
        size="large"
      >
        <Tooltip
          title={renderNoteTooltip(
            row.note === null || row.note === undefined ? 'No Notes' : row.note
          )}
          placement="bottom"
        >
          <InfoOutlined />
        </Tooltip>
      </IconButton>
    );

  const renderNotesButton = (row) =>
    row.status !== undefined && (
      <ActionDrawer
        customIcon={<InfoOutlined />}
        drawerTitle="Buyer Notes"
        disabled={!row.buyer_id}
        tooltip={
          row.note ? renderNoteTooltip(row.note) : renderNoteTooltip('No Notes')
        }
        content={
          <EditBuyerNotes
            row={row}
            onUpdateReport={getData}
            onChange={updateRow}
            site={props.site}
          />
        }
      />
    );

  const renderAddLeadReturn = (row) =>
    row.status !== undefined &&
    (row.buyer_type === '1' || row.buyer_type === '9') && (
      <div style={{ maxWidth: '10rem' }}>
        <ActionDrawer
          addIcon
          drawerTitle="Lead Returns"
          content={(toggleDrawer) => (
            <LeadReturns
              tableBuyer={row.buyer_two}
              tableSiteFilter={props.site}
              toggleDrawer={toggleDrawer}
            />
          )}
        />
      </div>
    );

  const renderSkippedLeads = (row) => (
    <ActionDrawer
      inline
      drawerTitle="Skipped Reasons"
      content={<SkippedReasons data={row.skipped_reasons} />}
    />
  );

  const renderReturnsBreakdown = (row) => (
    <ActionDrawer
      inline
      drawerTitle="Returns Affiliate Breakdown"
      content={<ReturnsBreakdown buyerName={row.buyer} />}
    />
  );

  const renderBreakdownButton = (row) => (
    <div>
      <ActionDrawer
        fullWidth
        drawerTitle="Affiliates Breakdown"
        customIcon={<List />}
        content={
          <BuyerAffiliatesBreakdown
            params={buildTableParams('getBuyerReport', props)}
            buyer={row.buyer}
            columns={breakdownColumns}
            cellComponent={cellComponent}
            endpoint="buyers"
            sortingColumn="revenue_final"
            csv
          />
        }
      />
    </div>
  );

  const cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'buyer':
        return renderReportLink(
          row[column.name],
          row.buyer,
          (value) => value,
          () => {},
          () => redirectReport('hourlyBuyerTotals', row.buyer),
          AccessTime
        );
      case 'affiliate_name':
        return renderReportLink(
          `${row.affiliate_name} (#${row.affiliate_id})`,
          row.buyer,
          (value) => value,
          () => {},
          () => redirectReport('hourlyBuyerTotals'),
          AccessTime
        );
      case 'pings':
      case 'pings_rejected':
      case 'posted':
      case 'sold':
      case 'leads':
      case 'bid_threshold_rejects':
        return renderNumberCell(row[column.name]);
      case 'return_percent':
      case 'conversion':
        return renderPercentCell(row[column.name]);
      case 'revenue_final':
      case 'revenue_reported':
        return !(
          typeof row[column.name] === 'string' &&
          row[column.name].trim() === 'NA'
        ) && typeof row[column.name] !== 'undefined'
          ? renderCurrencyCell(row[column.name])
          : renderDefaultCell('NA');
      case 'bid_average_by_pings':
        return renderCurrencyCell(row[column.name]);
      case 'apv':
      case 'asv':
        return !(
          typeof row[column.name] === 'string' &&
          row[column.name].trim() === 'NA'
        ) && typeof row[column.name] !== 'undefined'
          ? renderCurrencyCell(row[column.name])
          : renderDefaultCell('NA');
      case 'status':
        return renderDefaultCell(
          row.is_deleted === '0' ? renderToggle(row, 'status') : ''
        );
      case 'edit':
        return renderDefaultCell(
          <div style={{ display: 'flex' }}>
            {row.disable_settings
              ? renderNoEditButton(row)
              : renderEditButton(row)}
            {renderAddLeadReturn(row)}
            {location.includes('Setup')
              ? renderNotesButton(row)
              : renderReportNotesTooltip(row)}
          </div>
        );
      case 'skipped_leads':
        return props.time === 'all'
          ? renderDefaultCell(
              row[column.name],
              row.skipped_leads && row.skipped_leads > '0'
                ? renderSkippedLeads(row)
                : null
            )
          : renderDefaultCell('N/A');
      case 'unique_users_returns':
        return renderDefaultCell(
          row[column.name],
          row.unique_users_returns && +row.unique_users_returns > 0
            ? renderReturnsBreakdown(row)
            : null
        );
      case 'breakdown':
        return renderDefaultCell(renderBreakdownButton(row));
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  const renderBarChart = (width) => {
    const topTenAPV = getDataSorted(rows ?? [], 'apv').slice(0, 10);
    const mapAPVToInt = (rows) =>
      (rows ?? [])?.map((row) => ({ ...row, apv: +row.apv }));
    return (
      <BarChart
        width={
          buyerReport.current ? buyerReport.current.offsetWidth / 2 - 25 : width
        }
        data={mapAPVToInt(topTenAPV)}
        barNameKey="buyer"
        formatXAxis={formatXAxis}
        customTick
        bars={[
          {
            dataKey: 'apv',
            label: 'Average Post Value',
            color: colors.lenoxSuccess1,
            negativeColor: colors.lenoxDark2
          }
        ]}
        showDollar
      />
    );
  };

  const renderPieChart = (width) => {
    const newRows = getDataSorted(
      (rows ?? [])?.map((item) => ({
        ...item,
        revenue_final: parseFloat(item.revenue_final)
      })) ?? [],
      'revenue_final'
    );
    return (
      <PieChart
        width={
          buyerReport.current ? buyerReport.current.offsetWidth / 2 - 25 : width
        }
        data={newRows}
        dataKey="revenue_final"
        nameKey="buyer"
        title="Estimated Revenue"
        showDollar
      />
    );
  };

  const renderCharts = (chartWidth) =>
    !isLoading && (
      <ChartsWrapper>
        {renderBarChart(chartWidth)}
        {renderPieChart(chartWidth)}
      </ChartsWrapper>
    );

  const parseDataToDownload = (data) =>
    expectArrayData(data)?.map((item) => ({
      ...item,
      revenue_final: convertToFormattedNumber(item.revenue_final)
    }));

  const chartWidth =
    (props.menuOpen ? window.innerWidth * 0.8 : window.innerWidth) / 2;

  return (
    <div ref={buyerReport} id="buyer_report">
      {renderConfirmDialog()}
      {renderCharts(chartWidth)}
      {!isMobileDevice() && (
        <CSVButton
          params={props}
          columns={columns}
          filename="Buyer Report"
          data={parseDataToDownload([...(rows ?? [])])}
          showButton={Boolean((rows ?? []).length) && !isLoading}
        />
      )}
      {renderVisibilityCheckbox({
        text: 'HIDE ROWS WITH 0 REVENUE',
        handleHideShow,
        hide_rows
      })}
      <Table
        loading={isLoading}
        rows={
          hide_rows
            ? (rows ?? [])?.filter((row) => row.revenue_final > 0)
            : rows ?? []
        }
        columns={columns}
        onFiltersChange={onUpdateFiltersWithDebounce}
        filters={filters}
        defaultFilters={defaultFilterValue || []}
        cellComponent={cellComponent}
        defaultColumnWidths={getColumnWidths(columns)}
        getColumnCompare={getColumnCompare(columns)}
        tableColumnLocalStorageName="buyer_report"
        defaultSortingOrder={[
          { columnName: 'revenue_final', direction: 'desc' }
        ]}
        totalsMapping={totalMapping(props)}
        drawerTitle="Buyers"
        loadingError={isLoadingError}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    startDate: state.dateRange.startDate,
    endDate: state.dateRange.endDate,
    dateRange: state.dateRange.dateRange,
    peak: state.dateRange.peak,
    site: state.filters.site,
    legacy: state.filters.legacy,
    affiliateID: state.filters.affiliateID,
    menuOpen: state.pageReducer.menuOpen,
    breadCrumbs: state.pageReducer.breadCrumbs,
    trafficSource: state.filters.trafficSource,
    excludeBuyerType: state.filters.excludeBuyerType,
    time: state.filters.time
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updatePage,
        updateBreadCrumbs,
        updateFilters,
        handleGetDataChange,
        handleLastUpdatedChange
      },
      dispatch
    )
)(BuyerReport);
