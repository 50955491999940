import { lazy } from 'react';
import moment from 'moment';

import {
  checkAdmin,
  checkAffiliate,
  checkAffiliateManager,
  checkPowerUser,
  checkSuperAffiliate,
  checkTech,
  checkAmb,
  checkIsRemarketing,
  checkOPS
} from '../../PermissionsWrappers/permissionChecks';
import { filters as AffiliateReportingFilters } from '../../Reporting/Components/AffiliateReporting/filters/filters';
import { filters as AffiliateDetailsFilters } from '../../Reporting/Components/AffiliateDetails/filters/filters';
import { filters as PresellReportFilters } from '../../Reporting/Components/PresellReport/filters/filters';
import { filters as ExternalDataReportFilters } from '../../Reporting/Components/ExternalDataReport/filters/filters';
import { filters as AlvReportFilters } from '../../Reporting/Components/Alv/filters/filters';
import { filters as FormConversionReportFilters } from '../../Reporting/Components/FormConversion/filters/filters';
import { filters as LeadDataFilters } from '../../Reporting/Components/LeadData/filters/filters';
import { filters as stepReportFilters } from '../../Reporting/Components/StepByPlatform/filters';
import { filters as buyerReportFilters } from '../../Buyers/Components/BuyerReport/filters/filters';
import { filters as BuyerSummaryFilters } from '../../Buyers/Components/BuyerSummary/filters/filters';
import { filters as AverageBidsByStateFilters } from '../../Buyers/Components/AverageBidsByState/filters/filters';
import { filters as ErrorReportFilter } from '../../Buyers/Components/ErrorReport/filters/filters';
import { filters as TopStatesFilters } from '../../Reporting/Components/TopStates/filters/filters';
import { filters as LeadDemographicsFilters } from '../../Affiliates/Components/LeadDemographic/filters';
import { filters as TopAgesFilters } from '../../Reporting/Components/TopAges/filters/filters';
import { filters as MonthlyTotalsFilters } from '../../Reporting/Components/MonthlyTotals/filters/filters';
import { filters as PhoneTransferFilters } from '../../Reporting/Components/PhoneTransfer/filters/filters';
import { filters as InsuredUninsuredFilters } from '../../Reporting/Components/InsuredUninsured/filters';
import { filters as PlatformDeviceFilter } from '../../Reporting/Components/PlatformDeviceReporting/filters/filters';
import { filters as AffiliateFormConversionsFilter } from '../../Reporting/Components/AffiliateFormConversion/filters';
import { filters as FormTotalsFilter } from '../../Reporting/Components/FormTotals/filters';
import { filters as TYPlacementsFilters } from '../../Sites/Components/TYPlacements/filters';
import { filters as TYOffersFilters } from '../../Sites/Components/TYOffers/filters';
import { filters as TYPageOffersFilters } from '../../Sites/Components/TYPageOffers/filters';
import { filters as TYPagesFilters } from '../../Sites/Components/TYPages/filters';
import { filters as TYWallProvidersFilters } from '../../Sites/Components/TYWallProviders/filters';
import { filters as BadIPsFilters } from '../../Reporting/Components/BadIPs/filters';
import { filters as APVTrendFilters } from '../../Reporting/Components/APVTrend/filters';
import { filters as CarrierReportFilters } from '../../Reporting/Components/Carrier/filters';
import { filters as CarrierByRevenueReportFilters } from '../../Reporting/Components/CarrierByRevenue/filters';
import { filters as CarrierCPCReportFilters } from '../../Reporting/Components/CarrierCPC/filters';
import { filters as CampaignReportFilters } from '../../Reporting/Components/CampaignReport/filters';
import { filters as ClickwallCPCFilters } from '../../Reporting/Components/ClickwallCPC/filters';
import { filters as DynamicFormsFilters } from '../../Reporting/Components/DynamicForms/filters';
import { filters as APIReportFilters } from '../../Reporting/Components/API/filters';
import { filters as HourlyTotalsFilters } from '../../Reporting/Components/HourlyTotals/filters';
import { filters as HourlyBuyerFilters } from '../../Buyers/Components/HourlyBuyersReport/filters';
import { filters as StatesAlvFilters } from '../../Reporting/Components/StateAlv/filters/filters';
import { filters as AutoUserProfilesFilters } from '../../Reporting/Components/AutoUserProfiles/filters';
import { filters as WeekOverWeekFilters } from '../../Buyers/Components/WeekOverWeekReport/filters';
import { filters as UnsoldReportFilters } from '../../Buyers/Components/UnsoldReport/filters';
import { filters as AffiliateConversionFilters } from '../../Affiliates/Components/AffiliateConversions/filters';
import { filters as ClickPurchasesFilters } from '../../Reporting/Components/ClickPurchases/filters/filters';
import { filters as HourlyClickPurchasesFilters } from '../../Reporting/Components/ClickPurchases/filters/hourlyFilters';
import { filters as CompetitiveReportFilters } from '../../Reporting/Components/Competitive/filters';
import { filters as PPCReportFilters } from '../../Reporting/Components/PPC/filters';
import { filters as ClientMonthlyTotalsFilters } from '../../Reporting/Components/ClientMonthlyTotals/filters/filters';
import { filters as RemarketingFilters } from '../../Remarketing/utils/filters';
import { filters as MissedBidFilters } from '../../Reporting/Components/MissedBidReport/filters/filters';
import { filters as AMBStateFilters } from '../../Reporting/Components/AMBStateReport/filters/filters';
import { filters as AMBHourlyStateFilters } from '../../Reporting/Components/AMBStateReport/filters/hourlyFilters';
import { filters as LeadDemographicsAMBFilters } from '../../Reporting/Components/LeadDemographicsAMB/filters/filters';
import { filters as AMBLogFilters } from '../../Reporting/Components/AMBLogs/filters';

import { isMobileDevice } from '../../common/isMobileDevice';
import { type IRoute } from '../../../Utilities/types';

// Lazy load Components
const SiteTotals = lazy(
  () => import('../../Reporting/Components/SiteTotals/SiteTotals')
);
const AffiliateReporting = lazy(
  () =>
    import('../../Reporting/Components/AffiliateReporting/AffiliateReporting')
);
const AffiliateDetails = lazy(
  () => import('../../Reporting/Components/AffiliateDetails/AffiliateDetails')
);
const LeadDemographic = lazy(
  () => import('../../Affiliates/Components/LeadDemographic')
);
const NewLeadDemographic = lazy(
  () =>
    import('../../Affiliates/Components/NewLeadDemographic/NewLeadDemographic')
);
const BuyerReport = lazy(
  () => import('../../Buyers/Components/BuyerReport/BuyerReport')
);
const BuyerLegsReport = lazy(
  () => import('../../Buyers/Components/BuyerLegs/BuyerLegs')
);
const BuyerSummaryReport = lazy(
  () => import('../../Buyers/Components/BuyerSummary/BuyerSummary')
);
const ErrorReport = lazy(
  () => import('../../Buyers/Components/ErrorReport/ErrorReport')
);
const PresellReport = lazy(
  () => import('../../Reporting/Components/PresellReport/PresellReport')
);
const ExternalDataReport = lazy(
  () =>
    import('../../Reporting/Components/ExternalDataReport/ExternalDataReport')
);
const AlvReport = lazy(
  () => import('../../Reporting/Components/Alv/AlvReport')
);
const FormConversionReport = lazy(
  () => import('../../Reporting/Components/FormConversion/FormConversionReport')
);
const TYOffers = lazy(() => import('../../Sites/Components/TYOffers/TYOffers'));
const AddTYOffers = lazy(
  () => import('../../Sites/Components/AddTYOffers/render/AddTYOffers')
);
const LinkTYOffers = lazy(
  () => import('../../Sites/Components/TYPageOffers/LinkTYOffers')
);
const TYPages = lazy(() => import('../../Sites/Components/TYPages/TYPages'));
const AddTYPage = lazy(
  () => import('../../Sites/Components/TYPages/AddTYPage')
);
const TYPageOffers = lazy(
  () => import('../../Sites/Components/TYPageOffers/TYPageOffers')
);
const PageOffersPosition = lazy(
  () => import('../../Sites/Components/TYPageOffers/PageOffersPosition')
);
const CarrierReport = lazy(
  () => import('../../Reporting/Components/Carrier/CarrierReport')
);
const CampaignReport = lazy(
  () => import('../../Reporting/Components/CampaignReport/CampaignReport')
);
const TopStates = lazy(
  () => import('../../Reporting/Components/TopStates/TopStates')
);
const LeadData = lazy(
  () => import('../../Reporting/Components/LeadData/LeadData')
);
const InsuredUninsured = lazy(
  () => import('../../Reporting/Components/InsuredUninsured/index')
);
const MonthlyTotals = lazy(
  () => import('../../Reporting/Components/MonthlyTotals/MonthlyTotals')
);
const PhoneTransferReport = lazy(
  () => import('../../Reporting/Components/PhoneTransfer/PhoneTransferReport')
);
const HourlyTotals = lazy(
  () => import('../../Reporting/Components/HourlyTotals/HourlyTotals')
);
const HourlyBuyerReport = lazy(
  () => import('../../Buyers/Components/HourlyBuyersReport')
);
const StateAlvReport = lazy(
  () => import('../../Reporting/Components/StateAlv/StateAlvReport')
);
const StepReport = lazy(
  () => import('../../Reporting/Components/StepByPlatform')
);
const AffiliateFormConversion = lazy(
  () =>
    import(
      '../../Reporting/Components/AffiliateFormConversion/AffiliateFormConversion'
    )
);
const TYPlacements = lazy(
  () => import('../../Sites/Components/TYPlacements/TYPlacements')
);
const TYWallProviders = lazy(
  () => import('../../Sites/Components/TYWallProviders/TYWallProviders')
);
const FormTotals = lazy(
  () => import('../../Reporting/Components/FormTotals/FormTotals')
);
const BadIPs = lazy(() => import('../../Reporting/Components/BadIPs/BadIPs'));
const AutoUserProfiles = lazy(
  () => import('../../Reporting/Components/AutoUserProfiles/AutoUserProfiles')
);
const APVTrend = lazy(
  () => import('../../Reporting/Components/APVTrend/APVTrend')
);
const ClickwallCPC = lazy(
  () => import('../../Reporting/Components/ClickwallCPC/ClickwallCPC')
);
const DynamicForms = lazy(
  () => import('../../Reporting/Components/DynamicForms/DynamicForms')
);
const APIReport = lazy(
  () => import('../../Reporting/Components/API/APIReport')
);
const ClickPurchases = lazy(
  () => import('../../Reporting/Components/ClickPurchases/ClickPurchases')
);
const HourlyClickPurchases = lazy(
  () => import('../../Reporting/Components/ClickPurchases/HourlyClickPurchases')
);
const WeekOverWeek = lazy(
  () => import('../../Buyers/Components/WeekOverWeekReport')
);
const CarrierCPCReport = lazy(
  () => import('../../Reporting/Components/CarrierCPC/CarrierCPCReport')
);

const CarrierByRevenue = lazy(
  () => import('../../Reporting/Components/CarrierByRevenue/CarrierByRevenue')
);
const UnsoldReport = lazy(
  () => import('../../Buyers/Components/UnsoldReport/UnsoldReport')
);
const MissedBidReport = lazy(
  () => import('../../Reporting/Components/MissedBidReport/MissedBidReport')
);
const AMBStateReport = lazy(
  () => import('../../Reporting/Components/AMBStateReport/AMBStateReport')
);
const AMBHourlyStateReport = lazy(
  () => import('../../Reporting/Components/AMBStateReport/AMBHourlyStateReport')
);
const LeadDemographicsAMB = lazy(
  () =>
    import('../../Reporting/Components/LeadDemographicsAMB/LeadDemographicsAMB')
);
const AffiliateConversion = lazy(
  () =>
    import(
      '../../Affiliates/Components/AffiliateConversions/AffiliateConversions'
    )
);
const CompetitiveReport = lazy(
  () => import('../../Reporting/Components/Competitive/CompetitiveReport')
);
const PlatformDeviceReport = lazy(
  () =>
    import(
      '../../Reporting/Components/PlatformDeviceReporting/PlatformReporting'
    )
);
const ClientMonthlyTotals = lazy(
  () =>
    import('../../Reporting/Components/ClientMonthlyTotals/ClientMonthlyTotals')
);
const PPCReport = lazy(
  () => import('../../Reporting/Components/PPC/PPCReport')
);
const AverageBidsByState = lazy(
  () => import('../../Buyers/Components/AverageBidsByState/AverageBidsByState')
);
const TopAges = lazy(
  () => import('../../Reporting/Components/TopAges/TopAges')
);
const AMBLogsReport = lazy(
  () => import('../../Reporting/Components/AMBLogs/LogsReport')
);
const Remarketing = lazy(() => import('../../Remarketing/Remarketing'));

const ALL_REPORTING_PAGES = [
  'site_totals',
  'affiliate_reporting',
  'buyer_report',
  'affiliate_form_conversion_report',
  'autoUserProfiles',
  'monthly_report',
  'carrier',
  'lead_demographic',
  'new_lead_demographic',
  'affiliate_details',
  'affiliate_conversions',
  'hour_report',
  'platform_device',
  'insured_uninsured_report',
  'bad_ips',
  'PPC_report',
  'buyer_legs_report',
  'hourlyBuyerTotals',
  'phone_transfer',
  'error_report',
  'carrier_by_revenue',
  'carrier_CPC_report',
  'unsold_report',
  'external_data_report',
  'average_bids_by_state_report',
  'form_conversion_report',
  'form_totals_report',
  'presell_report',
  'stepReport',
  'dynamicForms',
  'alv_report',
  'states_alv',
  'ages_alv',
  'tier_state_auto',
  'apvTrend',
  'ty_offers',
  'ty_pages',
  'ty_placements',
  'ty_wall_providers',
  'ty_page_offers',
  'clickwallCPC',
  'lb_placements',
  'lb_wall_providers',
  'client_monthly_report',
  'buyer_summary_report',
  'lead_data',
  'api',
  'remarketing',
  'competitive_report',
  'clickPurchases',
  'hourlyClickPurchases',
  'missed_bid_report',
  'amb_state_report',
  'amb_hourly_state_report',
  'amb_lead_demographics',
  'week_over_week_report',
  'amb_logs',
  'native_campaigns'
] as const;

type AllReportingPagesType = (typeof ALL_REPORTING_PAGES)[number];
type ReportingListType = Record<AllReportingPagesType, IRoute>;

// Page Names
const TOP_PAGES = 'Top Pages';
const OVERVIEW = 'Overview';
const TRAFFIC = 'Traffic';
const BUYERS = 'Buyers';
const FORMS_PRESELLS = 'Forms / Presells';
const ALV = 'RPC';
const THANK_YOU = 'Thank You';
const LEAVE_BEHIND = 'Leave Behind';
const CLICK_PURCHASES = 'AMB';
const ACCOUNTING = 'Accounting';
const REMARKETING_NAME = 'Remarketing';
const NATIVE = 'Native';

// Page Order
export const REPORTING_PAGE_ORDER = [
  TOP_PAGES,
  OVERVIEW,
  TRAFFIC,
  BUYERS,
  FORMS_PRESELLS,
  ALV,
  THANK_YOU,
  LEAVE_BEHIND,
  ACCOUNTING,
  REMARKETING_NAME,
  NATIVE
];

const remarketingPageEntity = {
  remarketing: {
    section: [REMARKETING_NAME, 'Reporting'],
    collapsableSectionTitle: [REMARKETING_NAME],
    displayName: 'Remarketing',
    content: <Remarketing />,
    filters: RemarketingFilters(),
    displayDateRange: true
  }
};

const formTotalsPageEntity = {
  form_totals_report: {
    section: 'Reporting',
    collapsableSectionTitle: [FORMS_PRESELLS],
    displayName: 'Form Totals',
    content: <FormTotals />,
    displayDateRange: true,
    newRanges: true,
    filters: FormTotalsFilter()
  }
};

const reportingList = () => ({
  // Top Pages
  site_totals: {
    section: [TOP_PAGES, 'Reporting'],
    collapsableSectionTitle: [OVERVIEW, TOP_PAGES],
    displayName: 'Sites',
    content: <SiteTotals />,
    displayDateRange: true,
    newRanges: true
  },
  affiliate_reporting: {
    section: [TOP_PAGES, 'Reporting'],
    collapsableSectionTitle: [OVERVIEW, TRAFFIC, TOP_PAGES],
    displayName: 'Affiliates',
    content: <AffiliateReporting />,
    displayDateRange: true,
    newRanges: true,
    filters: AffiliateReportingFilters()
  },
  buyer_report: {
    section: [TOP_PAGES, 'Reporting'],
    collapsableSectionTitle: [TOP_PAGES, BUYERS, OVERVIEW],
    displayName: 'Buyers',
    content: <BuyerReport />,
    displayDateRange: true,
    newRanges: true,
    filters: buyerReportFilters()
  },
  affiliate_form_conversion_report: {
    section: [TOP_PAGES, 'Reporting'],
    collapsableSectionTitle: [TRAFFIC, TOP_PAGES],
    displayName: 'Affiliate - Form Conversion',
    content: <AffiliateFormConversion />,
    displayDateRange: true,
    newRanges: true,
    filters: AffiliateFormConversionsFilter()
  },
  autoUserProfiles: {
    section: [TOP_PAGES, 'Reporting'],
    collapsableSectionTitle: [TOP_PAGES],
    displayName: 'Auto User Profiles',
    content: <AutoUserProfiles />,
    filters: AutoUserProfilesFilters()
  },

  // Overview
  monthly_report: {
    section: 'Reporting',
    collapsableSectionTitle: [OVERVIEW],
    displayName: 'Monthly Totals',
    content: <MonthlyTotals />,
    displayDateRange: false,
    filters: MonthlyTotalsFilters()
  },
  carrier_by_revenue: {
    section: [OVERVIEW, 'Reporting'],
    collapsableSectionTitle: [OVERVIEW],
    displayName: 'Revenue By Carrier',
    content: <CarrierByRevenue />,
    displayDateRange: true,
    newRanges: true,
    filters: CarrierByRevenueReportFilters()
  },
  carrier: {
    section: 'Reporting',
    collapsableSectionTitle: [OVERVIEW],
    displayName: 'Carriers',
    displayDateRange: true,
    newRanges: true,
    content: <CarrierReport />,
    filters: CarrierReportFilters()
  },

  native_campaigns: {
    section: 'Reporting',
    collapsableSectionTitle: [NATIVE],
    displayName: 'Campaigns',
    displayDateRange: true,
    newRanges: true,
    content: <CampaignReport />,
    filters: CampaignReportFilters(),
    hasSubPaths: true,
    subPathsFilters: {
      all: undefined
    }
  },

  // Traffic
  lead_demographic: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'Lead Demographics',
    content: <LeadDemographic />,
    filters: LeadDemographicsFilters()
  },
  new_lead_demographic: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'Lead Demographics - RPC',
    content: <NewLeadDemographic />,
    displayDateRange: true,
    filters: LeadDemographicsFilters()
  },
  affiliate_details: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'SubID Report',
    content: <AffiliateDetails />,
    displayDateRange: true,
    newRanges: true,
    filters: AffiliateDetailsFilters()
  },
  affiliate_conversions: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'Conversions',
    displayDateRange: true,
    newRanges: true,
    hideAllTime: true,
    dateRangeLimit: moment()
      .startOf('month')
      .subtract(1, 'months')
      .format('MM/DD/YYYY'),
    content: <AffiliateConversion />,
    filters: AffiliateConversionFilters()
  },
  hour_report: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'Hourly Totals',
    content: <HourlyTotals />,
    displayDateRange: true,
    newRanges: true,
    filters: HourlyTotalsFilters()
  },
  platform_device: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'Device/Platform',
    displayDateRange: true,
    newRanges: true,
    content: <PlatformDeviceReport />,
    filters: PlatformDeviceFilter()
  },
  insured_uninsured_report: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'Insured/Uninsured',
    content: <InsuredUninsured />,
    displayDateRange: true,
    newRanges: true,
    filters: InsuredUninsuredFilters()
  },
  bad_ips: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'Bad IP',
    content: <BadIPs />,
    displayDateRange: true,
    newRanges: true,
    filters: BadIPsFilters()
  },
  PPC_report: {
    section: 'Reporting',
    collapsableSectionTitle: [TRAFFIC],
    displayName: 'PPC',
    content: <PPCReport />,
    displayDateRange: true,
    newRanges: true,
    filters: PPCReportFilters()
  },

  // BUYERS
  buyer_legs_report: {
    section: [BUYERS, 'Reporting'],
    collapsableSectionTitle: [BUYERS],
    displayName: 'Buyer Legs',
    content: <BuyerLegsReport />,
    displayDateRange: true,
    newRanges: true
  },
  hourlyBuyerTotals: {
    section: 'Reporting',
    collapsableSectionTitle: [BUYERS],
    displayName: 'Hourly Buyer Totals',
    content: <HourlyBuyerReport />,
    displayDateRange: true,
    newRanges: true,
    filters: HourlyBuyerFilters()
  },
  phone_transfer: {
    section: 'Reporting',
    collapsableSectionTitle: [BUYERS],
    displayName: 'Phone Transfer',
    displayDateRange: true,
    newRanges: true,
    content: <PhoneTransferReport />,
    filters: PhoneTransferFilters()
  },
  error_report: {
    section: 'Reporting',
    collapsableSectionTitle: [BUYERS],
    displayName: 'Errors',
    content: <ErrorReport />,
    displayDateRange: true,
    newRanges: true,
    filters: ErrorReportFilter()
  },
  carrier_CPC_report: {
    section: [OVERVIEW, 'Reporting'],
    collapsableSectionTitle: [BUYERS],
    displayName: 'Carrier CPC',
    content: <CarrierCPCReport />,
    displayDateRange: true,
    newRanges: true,
    filters: CarrierCPCReportFilters()
  },
  unsold_report: {
    section: ['Reporting'],
    collapsableSectionTitle: [BUYERS],
    displayName: 'Unsold',
    content: <UnsoldReport />,
    displayDateRange: true,
    newRanges: true,
    filters: UnsoldReportFilters()
  },
  external_data_report: {
    collapsableSectionTitle: [BUYERS],
    displayName: 'External Data',
    content: <ExternalDataReport />,
    displayDateRange: true,
    newRanges: true,
    filters: ExternalDataReportFilters()
  },
  average_bids_by_state_report: {
    collapsableSectionTitle: [BUYERS],
    displayName: 'Average Bids By State',
    content: <AverageBidsByState />,
    displayDateRange: true,
    newRanges: true,
    filters: AverageBidsByStateFilters()
  },

  // FORMS/PRESELLS
  form_conversion_report: {
    section: 'Reporting',
    collapsableSectionTitle: [FORMS_PRESELLS],
    displayName: 'Form Conversion',
    content: <FormConversionReport />,
    displayDateRange: false,
    filters: FormConversionReportFilters()
  },
  ...formTotalsPageEntity,
  presell_report: {
    section: 'Reporting',
    collapsableSectionTitle: [FORMS_PRESELLS],
    displayName: 'Presells',
    content: <PresellReport />,
    displayDateRange: true,
    newRanges: true,
    filters: PresellReportFilters()
  },
  stepReport: {
    section: 'Reporting',
    collapsableSectionTitle: [FORMS_PRESELLS],
    displayName: 'Step Report',
    content: <StepReport />,
    displayDateRange: true,
    newRanges: true,
    filters: stepReportFilters()
  },
  dynamicForms: {
    section: 'Reporting',
    collapsableSectionTitle: [FORMS_PRESELLS],
    displayName: 'Dynamic Forms',
    displayDateRange: true,
    newRanges: true,
    content: <DynamicForms />,
    filters: DynamicFormsFilters()
  },

  // RPC
  alv_report: {
    section: 'Reporting',
    collapsableSectionTitle: [ALV],
    displayName: ALV,
    content: <AlvReport />,
    displayDateRange: false,
    filters: AlvReportFilters()
  },
  states_alv: {
    section: 'Reporting',
    collapsableSectionTitle: [ALV],
    displayName: 'RPC By State',
    content: <TopStates />,
    displayDateRange: true,
    newRanges: true,
    filters: TopStatesFilters()
  },
  ages_alv: {
    section: 'Reporting',
    collapsableSectionTitle: [ALV],
    displayName: 'RPC By Age',
    content: <TopAges />,
    displayDateRange: true,
    newRanges: true,
    filters: TopAgesFilters()
  },
  tier_state_auto: {
    section: 'Reporting',
    collapsableSectionTitle: [ALV],
    displayName: 'RPC By Tier/State (Auto)',
    content: <StateAlvReport />,
    displayDateRange: true,
    newRanges: true,
    filters: StatesAlvFilters()
  },
  apvTrend: {
    section: 'Reporting',
    collapsableSectionTitle: [ALV],
    displayName: 'RPC Trend',
    content: <APVTrend />,
    displayDateRange: true,
    newRanges: true,
    filters: APVTrendFilters()
  },

  // Thank You
  ty_offers: {
    section: 'Reporting',
    collapsableSectionTitle: [THANK_YOU],
    displayName: 'TY Offers',
    displayDateRange: true,
    newRanges: true,
    content: <TYOffers />,
    filters: TYOffersFilters(),
    actionItems: [{ drawerTitle: 'Add TY Offers', content: <AddTYOffers /> }]
  },
  ty_pages: {
    section: 'Reporting',
    collapsableSectionTitle: [THANK_YOU],
    displayName: 'TY Pages',
    content: <TYPages />,
    displayDateRange: true,
    newRanges: true,
    filters: TYPagesFilters(),
    actionItems: [
      {
        drawerTitle: 'Add TY Page',
        content: (toggleDrawer: boolean, updateParent: () => void) => (
          <AddTYPage toggleDrawer={toggleDrawer} updateParent={updateParent} />
        )
      }
    ]
  },
  ty_placements: {
    section: 'Reporting',
    collapsableSectionTitle: [THANK_YOU],
    displayName: 'TY Placements',
    displayDateRange: true,
    newRanges: true,
    content: <TYPlacements />,
    filters: TYPlacementsFilters()
  },
  ty_wall_providers: {
    section: 'Reporting',
    collapsableSectionTitle: [THANK_YOU],
    displayName: 'TY Wall Carriers',
    content: <TYWallProviders />,
    displayDateRange: true,
    newRanges: true,
    filters: TYWallProvidersFilters()
  },
  ty_page_offers: {
    section: 'Reporting',
    collapsableSectionTitle: [THANK_YOU],
    displayName: 'TY Page Offers',
    content: <TYPageOffers />,
    displayDateRange: true,
    newRanges: true,
    filters: TYPageOffersFilters(),
    hidden: true,
    actionItems: [
      {
        drawerTitle: 'Link TY Offers',
        content: (toggleDrawer: boolean, updateParent: () => void) => (
          <LinkTYOffers
            toggleDrawer={toggleDrawer}
            updateParent={updateParent}
          />
        )
      },
      {
        drawerTitle: 'Change Offers Position',
        content: (toggleDrawer: boolean, updateParent: () => void) => (
          <PageOffersPosition
            toggleDrawer={toggleDrawer}
            updateParent={updateParent}
          />
        )
      }
    ]
  },
  clickwallCPC: {
    section: 'Reporting',
    collapsableSectionTitle: [THANK_YOU],
    displayName: 'TY Clickwall CPC',
    displayDateRange: true,
    newRanges: true,
    content: <ClickwallCPC />,
    filters: ClickwallCPCFilters()
  },

  // Leave Behind
  lb_placements: {
    section: 'Reporting',
    collapsableSectionTitle: [LEAVE_BEHIND],
    displayName: 'LB Placements',
    displayDateRange: true,
    newRanges: true,
    content: <TYPlacements LB />,
    filters: TYPlacementsFilters(true)
  },
  lb_wall_providers: {
    section: 'Reporting',
    collapsableSectionTitle: [LEAVE_BEHIND],
    displayName: 'LB Wall Carriers',
    content: <TYWallProviders LB />,
    displayDateRange: true,
    newRanges: true,
    filters: TYWallProvidersFilters()
  },
  client_monthly_report: {
    section: 'Reporting',
    collapsableSectionTitle: [ACCOUNTING],
    displayName: 'Client Monthly Totals',
    content: <ClientMonthlyTotals />,
    displayDateRange: false,
    filters: ClientMonthlyTotalsFilters()
  },
  buyer_summary_report: {
    section: 'Reports',
    displayName: 'Buyer Summary',
    content: <BuyerSummaryReport />,
    filters: BuyerSummaryFilters(),
    collapsableSectionTitle: [ACCOUNTING]
  },
  ...remarketingPageEntity,
  // Legacy
  lead_data: {
    section: 'Reporting',
    collapsableSectionTitle: ['Legacy'],
    displayName: 'Lead Lookup',
    content: <LeadData />,
    filters: LeadDataFilters()
  }
  /* sms_revenue: {
    section: 'Reporting',
    displayName: 'SMS Revenue',
    content: <SMSRevenue />,
    displayDateRange: true,
    filters: SMSRevenueFilters(),
  }, */
});

const reportingListResult = reportingList();

const reportingListAffInternal: Partial<ReportingListType> = {
  site_totals: reportingListResult.site_totals,
  affiliate_reporting: reportingListResult.affiliate_reporting,
  affiliate_details: reportingListResult.affiliate_details,
  presell_report: reportingListResult.presell_report,
  alv_report: reportingListResult.alv_report,
  ages_alv: reportingListResult.ages_alv,
  states_alv: reportingListResult.states_alv,
  tier_state_auto: reportingListResult.tier_state_auto,
  phone_transfer: reportingListResult.phone_transfer,
  lead_demographic: reportingListResult.lead_demographic,
  new_lead_demographic: reportingListResult.new_lead_demographic,
  platform_device: reportingListResult.platform_device,
  insured_uninsured_report: reportingListResult.insured_uninsured_report,
  hour_report: reportingListResult.hour_report,
  bad_ips: reportingListResult.bad_ips,
  affiliate_conversions: reportingListResult.affiliate_conversions,
  PPC_report: reportingListResult.PPC_report
};

// hide only works with collapsable section titles, use hidden if not collapsable

const reportingListAmb: Partial<ReportingListType> = {
  clickPurchases: {
    section: [CLICK_PURCHASES],
    collapsableSectionTitle: [CLICK_PURCHASES],
    displayName: 'Seller',
    content: <ClickPurchases />,
    displayDateRange: true,
    newRanges: true,
    filters: ClickPurchasesFilters()
  },
  amb_logs: {
    section: CLICK_PURCHASES,
    collapsableSectionTitle: [CLICK_PURCHASES],
    displayName: 'Logs',
    displayDateRange: true,
    content: <AMBLogsReport />,
    filters: AMBLogFilters()
  },
  hourlyClickPurchases: {
    section: CLICK_PURCHASES,
    collapsableSectionTitle: [CLICK_PURCHASES],
    displayName: 'Hourly Seller',
    content: <HourlyClickPurchases />,
    displayDateRange: true,
    newRanges: true,
    filters: HourlyClickPurchasesFilters(),
    hidden: true
  },
  missed_bid_report: {
    section: CLICK_PURCHASES,
    collapsableSectionTitle: [CLICK_PURCHASES],
    displayName: 'Opportunity',
    content: <MissedBidReport />,
    displayDateRange: true,
    newRanges: true,
    filters: MissedBidFilters()
  },
  amb_state_report: {
    section: CLICK_PURCHASES,
    collapsableSectionTitle: [CLICK_PURCHASES],
    displayName: 'State',
    content: <AMBStateReport />,
    displayDateRange: true,
    newRanges: true,
    filters: AMBStateFilters()
  },
  amb_hourly_state_report: {
    section: CLICK_PURCHASES,
    collapsableSectionTitle: [CLICK_PURCHASES],
    displayName: 'Seller Hourly State Report',
    content: <AMBHourlyStateReport />,
    displayDateRange: true,
    newRanges: true,
    hidden: true,
    filters: AMBHourlyStateFilters()
  },
  amb_lead_demographics: {
    section: CLICK_PURCHASES,
    collapsableSectionTitle: [CLICK_PURCHASES],
    displayName: 'Lead Demographics - AMB',
    content: <LeadDemographicsAMB />,
    displayDateRange: true,
    newRanges: true,
    filters: LeadDemographicsAMBFilters()
  },
  week_over_week_report: {
    section: 'Reporting',
    collapsableSectionTitle: [OVERVIEW],
    displayName: 'Week Over Week',
    content: <WeekOverWeek />,
    displayDateRange: true,
    dateRangeLimit: moment().subtract(24, 'w').format('YYYY-MM-DD'),
    specialRange: 'weekOverWeek',
    filters: WeekOverWeekFilters()
  },
  site_totals: reportingListResult.site_totals,
  affiliate_reporting: reportingListResult.affiliate_reporting,
  affiliate_details: reportingListResult.affiliate_details,
  alv_report: reportingListResult.alv_report,
  ages_alv: reportingListResult.ages_alv,
  states_alv: reportingListResult.states_alv,
  lead_demographic: reportingListResult.lead_demographic,
  new_lead_demographic: reportingListResult.new_lead_demographic,
  insured_uninsured_report: reportingListResult.insured_uninsured_report,
  hour_report: reportingListResult.hour_report,
  affiliate_conversions: reportingListResult.affiliate_conversions,
  affiliate_form_conversion_report:
    reportingListResult.affiliate_form_conversion_report
};

const reportingListAffExternal: Partial<ReportingListType> = {
  site_totals: reportingListResult.site_totals,
  affiliate_reporting: reportingListResult.affiliate_reporting,
  affiliate_details: reportingListResult.affiliate_details,
  presell_report: reportingListResult.presell_report,
  lead_demographic: reportingListResult.lead_demographic,
  hour_report: reportingListResult.hour_report,
  affiliate_conversions: reportingListResult.affiliate_conversions
};

export const ReportingPageList = (): Partial<ReportingListType> => {
  if (checkTech()) {
    return {
      ...reportingList(),
      api: {
        section: 'Reporting',
        collapsableSectionTitle: [TRAFFIC],
        displayName: 'API',
        content: <APIReport />,
        displayDateRange: true,
        newRanges: true,
        filters: APIReportFilters()
      },
      competitive_report: {
        section: 'Reporting',
        collapsableSectionTitle: [],
        displayName: 'Competitive',
        content: <CompetitiveReport />,
        hidden: true,
        displayDateRange: true,
        newRanges: true,
        filters: CompetitiveReportFilters()
      },
      clickPurchases: {
        section: [TOP_PAGES, CLICK_PURCHASES],
        collapsableSectionTitle: [TOP_PAGES, CLICK_PURCHASES],
        displayName: 'Seller',
        content: <ClickPurchases />,
        displayDateRange: true,
        newRanges: true,
        filters: ClickPurchasesFilters()
      },
      amb_logs: {
        section: CLICK_PURCHASES,
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Logs',
        displayDateRange: true,
        content: <AMBLogsReport />,
        filters: AMBLogFilters()
      },
      hourlyClickPurchases: {
        section: CLICK_PURCHASES,
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Hourly Seller',
        content: <HourlyClickPurchases />,
        displayDateRange: true,
        newRanges: true,
        hide: true,
        filters: HourlyClickPurchasesFilters()
      },
      missed_bid_report: {
        section: CLICK_PURCHASES,
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Opportunity',
        content: <MissedBidReport />,
        displayDateRange: true,
        newRanges: true,
        filters: MissedBidFilters()
      },
      amb_state_report: {
        section: 'Reporting',
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'State',
        content: <AMBStateReport />,
        displayDateRange: true,
        newRanges: true,
        filters: AMBStateFilters()
      },
      amb_hourly_state_report: {
        section: 'Reporting',
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Seller Hourly State Report',
        content: <AMBHourlyStateReport />,
        displayDateRange: true,
        newRanges: true,
        hide: true,
        filters: AMBHourlyStateFilters()
      },
      amb_lead_demographics: {
        section: 'Reporting',
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Lead Demographics - AMB',
        content: <LeadDemographicsAMB />,
        displayDateRange: true,
        newRanges: true,
        filters: LeadDemographicsAMBFilters()
      },
      week_over_week_report: {
        section: 'Reporting',
        collapsableSectionTitle: [OVERVIEW],
        displayName: 'Week Over Week',
        content: <WeekOverWeek />,
        displayDateRange: true,
        dateRangeLimit: moment().subtract(24, 'w').format('YYYY-MM-DD'),
        specialRange: 'weekOverWeek',
        filters: WeekOverWeekFilters()
      }
    };
  }
  if (checkAdmin()) {
    const allAdminsList = {
      competitive_report: {
        section: 'Reporting',
        collapsableSectionTitle: [],
        displayName: 'Competitive',
        content: <CompetitiveReport />,
        hidden: true,
        displayDateRange: true,
        newRanges: true,
        filters: CompetitiveReportFilters()
      },
      week_over_week_report: {
        section: 'Reporting',
        collapsableSectionTitle: [OVERVIEW],
        displayName: 'Week Over Week',
        content: <WeekOverWeek />,
        displayDateRange: true,
        dateRangeLimit: moment().subtract(24, 'w').format('YYYY-MM-DD'),
        specialRange: 'weekOverWeek',
        filters: WeekOverWeekFilters()
      }
    };

    if (checkOPS()) {
      return {
        ...reportingList(),
        ...allAdminsList
      };
    }

    return {
      ...reportingList(),
      ...allAdminsList,
      clickPurchases: {
        section: [TOP_PAGES, CLICK_PURCHASES],
        collapsableSectionTitle: [TOP_PAGES, CLICK_PURCHASES],
        displayName: 'Seller',
        content: <ClickPurchases />,
        displayDateRange: true,
        newRanges: true
      },
      amb_logs: {
        section: CLICK_PURCHASES,
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Logs',
        displayDateRange: true,
        content: <AMBLogsReport />,
        filters: AMBLogFilters()
      },
      hourlyClickPurchases: {
        section: CLICK_PURCHASES,
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Hourly AMB',
        content: <HourlyClickPurchases />,
        displayDateRange: true,
        newRanges: true,
        hide: true,
        filters: HourlyClickPurchasesFilters()
      },
      amb_state_report: {
        section: 'Reporting',
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'State',
        content: <AMBStateReport />,
        displayDateRange: true,
        newRanges: true,
        filters: AMBStateFilters()
      },
      amb_hourly_state_report: {
        section: 'Reporting',
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Seller Hourly State Report',
        content: <AMBHourlyStateReport />,
        displayDateRange: true,
        newRanges: true,
        hide: true,
        filters: AMBHourlyStateFilters()
      },
      amb_lead_demographics: {
        section: 'Reporting',
        collapsableSectionTitle: [CLICK_PURCHASES],
        displayName: 'Lead Demographics - AMB',
        content: <LeadDemographicsAMB />,
        displayDateRange: true,
        newRanges: true,
        filters: LeadDemographicsAMBFilters()
      }
    };
  }

  if (checkSuperAffiliate() || checkAffiliateManager() || checkPowerUser()) {
    // full list here
    return reportingListAffInternal;
  }

  if (checkIsRemarketing()) {
    return {
      ...remarketingPageEntity,
      ...formTotalsPageEntity,
      ...reportingListAffInternal
    };
  }

  if (checkAmb()) {
    return reportingListAmb;
  }

  if (checkAffiliate()) {
    return reportingListAffExternal;
  }

  return {};
};

export const sections = () => {
  if (checkAdmin() || checkTech()) {
    if (isMobileDevice()) {
      return [false, TOP_PAGES];
    }

    if (checkOPS()) {
      return [false, TOP_PAGES, 'Reporting', ACCOUNTING];
    }

    return [false, TOP_PAGES, 'Reporting', CLICK_PURCHASES, ACCOUNTING];
  }

  if (checkAmb()) {
    return [false, CLICK_PURCHASES, 'Reporting'];
  }

  if (
    checkSuperAffiliate() ||
    checkIsRemarketing() ||
    checkAffiliate() ||
    checkAffiliateManager() ||
    checkPowerUser()
  ) {
    return [false, 'Reporting'];
  }

  return [];
};
