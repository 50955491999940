import moment from 'moment';
import LeadsCouncilLogo from '../../assets/images/LeadsCouncil_Logo.png';
import MBPBadge from '../../assets/images/MBP_Badge.svg';
import MSPartnerBadge from '../../assets/images/MS_Partner_Badge.png';

const Footer = () => (
  <footer>
    <div className="container text-center">
      <div className="row">
        <div className="grid-xs-3">
          <a
            href="https://www.leadscouncil.org/about-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LeadsCouncilLogo}
              className="logos leadsCouncil"
              alt="logo"
              width={185}
              height={185}
            />
          </a>
        </div>
        <div className="grid-xs-3">
          <img
            src={MBPBadge}
            className="logos"
            alt="Facebook Business Partner"
            width={200}
            height={115}
          />
        </div>
        <div className="grid-xs-3">
          <a
            href="https://www.google.com/partners/agency?id=2176787140"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg"
              className="logos"
              alt="Google Partners"
              width={140}
              height={140}
            />
          </a>
        </div>
        <div className="grid-xs-3">
          <img
            src={MSPartnerBadge}
            className="logos microsoft"
            alt="Microsoft Advertising Partner"
            width={333}
            height={115}
          />
        </div>
      </div>
      <div className="row">
        <div className="grid-xs-12">
          <a href="/">Home</a> | <a href="/about.html">About</a> |{' '}
          <a href="mailto:support@useotto.tech">Contact Us</a>
          <div>
            <small>
              {moment().year()} Otto Quote, LLC - All Rights Reserved
            </small>
          </div>
        </div>
        <div className="grid-xs-12 " />
      </div>
    </div>
  </footer>
);

export default Footer;
