import {
  siteOptions,
  affiliateID,
  getTrafficSources,
  buyerList
} from '../../../common/commonRequests';
import { FORM_DATA_OPTIONS } from '../../../common/dropdownConstants';
import {
  checkAffiliate,
  checkSuperAffiliate,
  checkAmb,
  checkIsRemarketing
} from '../../../PermissionsWrappers/permissionChecks';

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: '2',
    inputWidth: 3
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'none',
    inputWidth: 3
  },
  buyer: {
    name: 'buyer',
    label: 'Buyers',
    type: 'select',
    options: (dependency) => buyerList(dependency, false, false, false, true),
    value: 'all',
    dependencyName: 'site'
  },
  trafficSource: {
    name: 'trafficSource',
    type: 'select',
    label: 'Traffic Source',
    defaultVal: '0',
    value: '0',
    inputWidth: 3,
    options: () => getTrafficSources(),
    hidden: checkAmb()
  },
  partial: {
    name: 'partial',
    type: 'select',
    label: 'Form Type',
    options: () => FORM_DATA_OPTIONS,
    hidden:
      checkAffiliate() ||
      checkSuperAffiliate() ||
      checkIsRemarketing() ||
      checkAmb()
  }
});
