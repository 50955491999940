export const USER_TYPE = '7aeab12b';
export const AFF_ID = '8aeab12a';
export const AGENT_FILTER = '00734';

export const USER_MAPPING = {
  '0598b43c': 'admin',
  eabd08c3: 'affiliate',
  ae829382: 'superAffiliate',
  e1a05451: 'agent',
  '3si87320c': 'accounting',
  '4r6dudplsl': 'tech',
  pp5rknajkj: 'affiliateManager',
  j09vettq34: 'powerUser',
  '5m1lxfic7v': 'amb',
  '9z3pgkds0i': 'isRemarketing',
  '2f6jfdtl4p': 'OPSUser'
};
