import { lazy } from 'react';
import { filters as agentStatusFilters } from '../../Agents/Components/AgentList/filters/filters';
import { filters as linksReportFilters } from '../../Sites/Components/Links/filters/filters';
import { filters as buyerReportFilters } from '../../Buyers/Components/BuyerReport/filters/filters';
import { filters as affiliatePixelsFilters } from '../../Affiliates/Components/AffiliatePixels/filters/filters';
import { filters as MediaAlphaPlacementsFilters } from '../../Affiliates/Components/MediaAlphaPlacements/filters';
import { filters as affiliateListFilters } from '../../Affiliates/Components/AffiliateList/filters';
import { filters as domainsFilters } from '../../Sites/Components/Domains/filters/filters';
import { filters as usersFilters } from '../../Reporting/Components/Users/filters';
import { filters as phoneNumberFilters } from '../../Affiliates/Components/PhoneNumber/filters';
import { filters as emailTemplateFilters } from '../../Sites/Components/AddEmailTemplate/defaultState/filters';
import { filters as TYOffersFilters } from '../../Sites/Components/TYOffers/filters';
import { filters as TYPageHeadersFilters } from '../../Sites/Components/TYPageHeaders/filters';
import {
  checkAdmin,
  checkAffiliate,
  checkAffiliateManager,
  checkPowerUser,
  checkSuperAffiliate,
  checkAmb,
  checkTech,
  checkIsRemarketing,
  checkOPS
} from '../../PermissionsWrappers/permissionChecks';
import { filters as TransparentlyPlacementsFilters } from '../../Affiliates/Components/TransparentlyPlacements/filters';
import { filters as QuinStreetPlacementsFilters } from '../../Affiliates/Components/QuinStreetPlacements/filters';
import { filters as EverQuoteSourceFilters } from '../../Affiliates/Components/EverQuoteSources/filters';
import { filters as BuyerMappingsFilters } from '../../Affiliates/Components/BuyerMappings/filters';
import { filters as formDescriptionFilters } from '../../Affiliates/Components/FormDescriptions/filters';
import { filters as SetupCarriersFilters } from '../../Buyers/Components/SetupCarriers/filters';
import { type IRoute } from '../../../Utilities/types';

const AffiliateList = lazy(
  () => import('../../Affiliates/Components/AffiliateList/AffiliateList')
);
const AffiliatePixels = lazy(
  () => import('../../Affiliates/Components/AffiliatePixels/AffiliatePixels')
);
const AddPixel = lazy(
  () => import('../../Affiliates/Components/AddPixel/render/AddPixel')
);
const Links = lazy(() => import('../../Sites/Components/Links'));
const AddCost = lazy(
  () => import('../../Sites/Components/AddCost/render/AddCost')
);
const AddS1Cost = lazy(
  () => import('../../Sites/Components/AddS1Cost/render/AddS1Cost')
);
const AgentList = lazy(
  () => import('../../Agents/Components/AgentList/AgentList')
);
const BuyerReport = lazy(
  () => import('../../Buyers/Components/BuyerReport/BuyerReport')
);
const TYOffers = lazy(() => import('../../Sites/Components/TYOffers/TYOffers'));
const Domains = lazy(() => import('../../Sites/Components/Domains/Domains'));
const AddTYOffers = lazy(
  () => import('../../Sites/Components/AddTYOffers/render/AddTYOffers')
);
const AddUser = lazy(
  () => import('../../Affiliates/Components/AddUser/render/AddUser')
);
const AddAPIUser = lazy(
  () => import('../../Reporting/Components/APIUsers/AddAPIUser')
);
const StateSuppression = lazy(
  () =>
    import('../../Buyers/Components/StateSuppression/render/StateSuppression')
);
const AddPhoneRevenue = lazy(
  () => import('../../Sites/Components/AddPhoneRevenue/render/AddPhoneRevenue')
);
const AddEmailRevenue = lazy(
  () => import('../../Sites/Components/AddEmailRevenue/render/AddEmailRevenue')
);
const UploadRetreaverRevenue = lazy(
  () =>
    import(
      '../../Sites/Components/UploadRetreaverRevenue/render/UploadRetreaverRevenue'
    )
);
const AddAdvertiserRevenue = lazy(
  () =>
    import(
      '../../Sites/Components/AddAdvertiserRevenue/render/AddAdvertiserRevenue'
    )
);
const AddDomain = lazy(
  () => import('../../Sites/Components/AddDomain/render/AddDomain')
);
const DenylistedEmailList = lazy(
  () => import('../../Sites/Components/DenylistedEmailList/DenylistedEmailList')
);
const AddDenylistedEmail = lazy(
  () =>
    import(
      '../../Sites/Components/AddDenylistedEmail/render/AddDenylistedEmail'
    )
);
const UsersReport = lazy(
  () => import('../../Reporting/Components/Users/Users')
);
const APIUsersReport = lazy(
  () => import('../../Reporting/Components/APIUsers/APIUsers')
);
const RequestToDelete = lazy(
  () =>
    import('../../Reporting/Components/RequestToDelete/render/RequestToDelete')
);
const EmailTemplate = lazy(
  () => import('../../Sites/Components/AddEmailTemplate/render/EmailTemplate')
);
const PhoneNumbers = lazy(
  () => import('../../Affiliates/Components/PhoneNumber/PhoneNumbers')
);
const AddPhoneNumber = lazy(
  () => import('../../Affiliates/Components/PhoneNumber/AddPhoneNumber')
);
const MediaAlphaPlacements = lazy(
  () =>
    import(
      '../../Affiliates/Components/MediaAlphaPlacements/MediaAlphaPlacements'
    )
);
const EditPlacement = lazy(
  () => import('../../Affiliates/Components/MediaAlphaPlacements/EditPlacement')
);
const TYPageHeaders = lazy(
  () => import('../../Sites/Components/TYPageHeaders/TYPageHeaders')
);
const AddTYPageHeaders = lazy(
  () => import('../../Sites/Components/TYPageHeaders/AddTYPageHeaders')
);
const AddAffiliate = lazy(
  () => import('../../Affiliates/Components/AddAffiliate')
);
const DNEForm = lazy(() => import('../../Agents/Components/DNEForm'));
const TransparentlyPlacements = lazy(
  () =>
    import(
      '../../Affiliates/Components/TransparentlyPlacements/TransparentlyPlacements'
    )
);
const TransparentlyEditPlacement = lazy(
  () =>
    import('../../Affiliates/Components/TransparentlyPlacements/EditPlacement')
);
const QuinStreetPlacements = lazy(
  () =>
    import(
      '../../Affiliates/Components/QuinStreetPlacements/QuinStreetPlacements'
    )
);
const QuinStreetEditPlacement = lazy(
  () => import('../../Affiliates/Components/QuinStreetPlacements/EditPlacement')
);
const EverQuoteSources = lazy(
  () => import('../../Affiliates/Components/EverQuoteSources/EverQuoteSources')
);
const EverQuoteEdit = lazy(
  () => import('../../Affiliates/Components/EverQuoteSources/EditPlacement')
);
const CopyPixel = lazy(
  () => import('../../Affiliates/Components/CopyPixel/CopyPixel')
);
const InternalAffiliateList = lazy(
  () =>
    import(
      '../../Affiliates/Components/InternalAffiliateList/InternalAffiliateList'
    )
);
const BuyerMappings = lazy(
  () => import('../../Affiliates/Components/BuyerMappings/BuyerMappings')
);
const DomainMappings = lazy(
  () => import('../../Affiliates/Components/DomainMappings/DomainMappings')
);
const EditBuyerMapping = lazy(
  () => import('../../Affiliates/Components/BuyerMappings/EditBuyerMapping')
);
const PendingAffiliateSignups = lazy(
  () => import('../../Affiliates/Components/PendingAffiliateSignups')
);
const InsertDomainMapping = lazy(
  () => import('../../Affiliates/Components/DomainMappings/InsertDomainMapping')
);
const FormDescriptions = lazy(
  () => import('../../Affiliates/Components/FormDescriptions/FormDescriptions')
);
const SetupCarriers = lazy(
  () => import('../../Buyers/Components/SetupCarriers/SetupCarriers')
);
const EditAddCarrier = lazy(
  () =>
    import(
      '../../Buyers/Components/SetupCarriers/EditAddCarrier/EditAddCarrier'
    )
);
const BulkUpdate = lazy(
  () => import('../../Affiliates/Components/BulkUpdate/BulkUpdate')
);
const BulkUpdateAutomaticTesting = lazy(
  () =>
    import(
      '../../Affiliates/Components/BulkUpdateAutomaticTesting/BulkUpdateAutomaticTesting'
    )
);
const NewLeadData = lazy(
  () => import('../../Reporting/Components/NewLeadData/render/NewLeadData')
);
const RequestToKnow = lazy(
  () => import('../../Reporting/Components/RequestToKnow/render/RequestToKnow')
);
const HomepageLinks = lazy(
  () => import('../../Affiliates/Components/HomepageLinks/HomepageLinks')
);

const setupListAdmin = {
  affiliate_list: {
    section: 'Affiliates',
    displayName: 'Affiliate List',
    filters: affiliateListFilters(),
    content: <AffiliateList />,
    actionItems: [
      {
        drawerTitle: 'Add Affiliate',
        content: (toggleDrawer: unknown) => (
          <AddAffiliate toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },

  links: {
    section: 'Affiliates',
    displayName: 'Links',
    content: <Links />,
    filters: linksReportFilters()
  },

  bulk_update: {
    section: 'Misc',
    displayName: 'OTTObot - Bulk Update',
    content: <BulkUpdate />,
    hidden: true
  },

  bulk_update_automatic_testing: {
    section: 'Misc',
    displayName: 'Automatic Testing - Bulk Update',
    content: <BulkUpdateAutomaticTesting />
  },

  pending_affiliate_signups: {
    section: 'Affiliates',
    displayName: 'Pending Affiliate Signups',
    content: <PendingAffiliateSignups />
  },

  homepage_links: {
    section: 'Affiliates',
    displayName: 'Homepage Links',
    content: <HomepageLinks />
  },

  phoneNumbers: {
    section: 'Affiliates',
    displayName: 'Phone Numbers',
    content: <PhoneNumbers />,
    filters: phoneNumberFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Phone Number',
        content: (toggleDrawer: unknown) => (
          <AddPhoneNumber toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },

  site_pixels: {
    section: 'Affiliates',
    displayName: 'Pixels',
    content: <AffiliatePixels />,
    filters: affiliatePixelsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Pixel',
        content: (toggleDrawer: unknown) => (
          <AddPixel toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  copy_pixels: {
    section: 'Affiliates',
    displayName: 'Copy Pixels',
    content: <CopyPixel />
  },
  quin_street_placements: {
    section: 'Affiliates',
    displayName: 'Quinstreet Placements',
    content: <QuinStreetPlacements />,
    filters: QuinStreetPlacementsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Placement',
        content: (toggleDrawer: unknown) => (
          <QuinStreetEditPlacement toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  ever_quote_sources: {
    section: 'Affiliates',
    displayName: 'EverQuote Sources',
    content: <EverQuoteSources />,
    filters: EverQuoteSourceFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Source',
        content: (toggleDrawer: unknown) => (
          <EverQuoteEdit toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  media_alpha_placements: {
    section: 'Affiliates',
    displayName: 'Media Alpha Placements',
    content: <MediaAlphaPlacements />,
    filters: MediaAlphaPlacementsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Placement',
        content: (toggleDrawer: unknown) => (
          <EditPlacement toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  transparently_placements: {
    section: 'Affiliates',
    displayName: 'Transparently Placements',
    content: <TransparentlyPlacements />,
    filters: TransparentlyPlacementsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Placement',
        content: (toggleDrawer: unknown) => (
          <TransparentlyEditPlacement toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  buyer_mapping: {
    section: 'Affiliates',
    displayName: 'Buyer Mappings',
    content: <BuyerMappings />,
    filters: BuyerMappingsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Buyer Mappings',
        content: (toggleDrawer: unknown, updateParent: unknown) => (
          <EditBuyerMapping
            toggleDrawer={toggleDrawer}
            updateParent={updateParent}
          />
        )
      }
    ]
  },
  domain_mapping: {
    section: 'Affiliates',
    displayName: 'Domain Mappings',
    content: <DomainMappings />,
    filters: BuyerMappingsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Domain Mappings',
        content: (toggleDrawer: unknown, updateParent: unknown) => (
          <InsertDomainMapping
            toggleDrawer={toggleDrawer}
            updateParent={updateParent}
          />
        )
      }
    ]
  },
  agent_list: {
    section: 'Agents',
    displayName: 'Agent List',
    content: <AgentList />,
    filters: agentStatusFilters()
  },

  buyer_report: {
    section: 'Buyers',
    displayName: 'Buyers',
    content: <BuyerReport />,
    displayDateRange: true,
    newRanges: true,
    filters: buyerReportFilters()
  },
  setup_carriers: {
    section: 'Buyers',
    displayName: 'Setup Carriers',
    content: <SetupCarriers />,
    filters: SetupCarriersFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Carrier',
        content: (toggleDrawer: unknown) => (
          <EditAddCarrier editMode={false} toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  ty_offers: {
    section: 'Sites',
    displayName: 'TY Offers',
    displayDateRange: true,
    newRanges: true,
    content: <TYOffers />,
    filters: TYOffersFilters(),
    actionItems: [{ drawerTitle: 'Add TY Offers', content: <AddTYOffers /> }]
  },
  ty_page_offers: {
    section: 'Sites',
    displayName: 'TY Page Headers',
    content: <TYPageHeaders />,
    filters: TYPageHeadersFilters(),
    actionItems: [
      {
        drawerTitle: 'Add TY Page Headers',
        content: (toggleDrawer: unknown, updateParent: unknown) => (
          <AddTYPageHeaders
            toggleDrawer={toggleDrawer}
            updateParent={updateParent}
          />
        )
      }
    ]
  },

  add_cost: {
    section: 'Sites',
    displayName: 'Add Cost',
    content: <AddCost />
  },

  add_s1_cost: {
    section: 'Sites',
    displayName: 'Add Cost - S1',
    content: <AddS1Cost />
  },
  email_denylist: {
    section: 'Misc',
    displayName: 'Email Denylist',
    content: <DenylistedEmailList />,
    actionItems: [
      {
        drawerTitle: 'Denylist Email',
        content: (toggleDrawer: unknown) => (
          <AddDenylistedEmail toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  state_suppression: {
    section: 'Buyers',
    displayName: 'State Suppression',
    content: <StateSuppression />
  },
  add_phone_revenue: {
    section: 'Sites',
    displayName: 'Add Phone Revenue',
    content: <AddPhoneRevenue />
  },
  add_email_revenue: {
    section: 'Sites',
    displayName: 'Add Email Revenue',
    content: <AddEmailRevenue />
  },
  add_advertiser_revenue: {
    section: 'Sites',
    displayName: 'Add Invoiced Revenue',
    content: <AddAdvertiserRevenue />
  },
  upload_retreaver_rev: {
    section: 'Sites',
    displayName: 'Upload Retreaver Rev',
    content: <UploadRetreaverRevenue />,
    hidden: true
  },
  domains: {
    section: 'Sites',
    displayName: 'Domains',
    content: <Domains />,
    filters: domainsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Domain',
        content: (toggleDrawer: unknown) => (
          <AddDomain toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  dneDncForm: {
    section: 'Misc',
    displayName: 'DNE and DNC',
    content: <DNEForm />
  },
  users: {
    section: 'Misc',
    displayName: 'Users',
    content: <UsersReport />,
    filters: usersFilters(),
    actionItems: [{ drawerTitle: 'Add User', content: <AddUser /> }]
  },
  apiUsers: {
    section: 'Misc',
    displayName: 'API Users',
    content: <APIUsersReport />,
    actionItems: [
      {
        drawerTitle: 'Add API User',
        content: (toggleDrawer: unknown, updateParent: unknown) => (
          <AddAPIUser toggleDrawer={toggleDrawer} updateParent={updateParent} />
        )
      }
    ]
  },
  requestToDelete: {
    section: 'Misc',
    displayName: 'Request To Delete',
    content: <RequestToDelete />
  },
  emailTemplate: {
    section: 'Misc',
    displayName: 'Abandoned Form Email',
    content: <EmailTemplate />,
    filters: emailTemplateFilters()
  },
  formDescriptions: {
    section: 'Affiliates',
    displayName: 'Form Descriptions',
    content: <FormDescriptions />,
    filters: formDescriptionFilters()
  },
  new_lead_data: {
    section: 'Misc',
    displayName: 'Auto Lead Data',
    content: <NewLeadData />
  },
  requestToKnow: {
    section: 'Misc',
    displayName: 'Request To Know',
    content: <RequestToKnow />
  }
};

const setupListAffExternal = {
  links: {
    section: 'Set Up',
    displayName: 'Links',
    content: <Links />,
    filters: linksReportFilters()
  },

  site_pixels: {
    section: 'Set Up',
    displayName: 'Pixels',
    content: <AffiliatePixels />,
    filters: affiliatePixelsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Pixel',
        content: (toggleDrawer: unknown) => (
          <AddPixel toggleDrawer={toggleDrawer} />
        )
      }
    ]
  }
};

const setupListAffInternal = {
  links: {
    section: 'Set Up',
    displayName: 'Links',
    content: <Links />,
    filters: linksReportFilters()
  },

  add_cost: {
    section: 'Set Up',
    displayName: 'Add Cost',
    content: <AddCost />
  },

  add_s1_cost: {
    section: 'Set Up',
    displayName: 'Add Cost - S1',
    content: <AddS1Cost />
  },

  site_pixels: {
    section: 'Set Up',
    displayName: 'Site Pixels',
    content: <AffiliatePixels />,
    filters: affiliatePixelsFilters(),
    actionItems: [
      {
        drawerTitle: 'Add Pixel',
        content: (toggleDrawer: unknown) => (
          <AddPixel toggleDrawer={toggleDrawer} />
        )
      }
    ]
  },
  ty_offers: {
    section: 'Sites',
    displayName: 'TY Offers',
    displayDateRange: true,
    newRanges: true,
    content: <TYOffers />,
    filters: TYOffersFilters(),
    actionItems: [{ drawerTitle: 'Add TY Offers', content: <AddTYOffers /> }]
  },
  affiliate_list: {
    section: 'Set Up',
    displayName: 'Internal Affiliate List',
    content: <InternalAffiliateList />,
    actionItems: [
      {
        drawerTitle: 'Add Affiliate',
        content: (toggleDrawer: unknown) => (
          <AddAffiliate toggleDrawer={toggleDrawer} />
        )
      }
    ]
  }
};

export const SetupPageList = (): Record<string, IRoute> => {
  if (checkAdmin() || checkTech()) {
    if (checkOPS()) {
      const {
        homepage_links,
        domains,
        agent_list,
        ...opsList
      }: Record<string, IRoute> = setupListAdmin;

      return opsList;
    }

    return setupListAdmin;
  }

  if (checkSuperAffiliate() || checkIsRemarketing() || checkAmb()) {
    return setupListAffInternal;
  }

  if (checkPowerUser()) {
    return {
      dneDncForm: {
        section: 'Set Up',
        displayName: 'DNE and DNC',
        content: <DNEForm />
      },
      phoneNumbers: {
        section: 'Set Up',
        displayName: 'Phone Numbers',
        content: <PhoneNumbers />,
        filters: phoneNumberFilters(),
        actionItems: [
          {
            drawerTitle: 'Add Phone Number',
            content: (toggleDrawer: unknown) => (
              <AddPhoneNumber toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      links: {
        section: 'Set Up',
        displayName: 'Links',
        content: <Links />,
        filters: linksReportFilters()
      },
      affiliate_list: {
        section: 'Set Up',
        displayName: 'Affiliate List',
        filters: affiliateListFilters(),
        content: <AffiliateList />,
        actionItems: [
          {
            drawerTitle: 'Add Affiliate',
            content: (toggleDrawer: unknown) => (
              <AddAffiliate toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      site_pixels: {
        section: 'Set Up',
        displayName: 'Pixels',
        content: <AffiliatePixels />,
        filters: affiliatePixelsFilters(),
        actionItems: [
          {
            drawerTitle: 'Add Pixel',
            content: (toggleDrawer: unknown) => (
              <AddPixel toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      pending_affiliate_signups: {
        section: 'Set Up',
        displayName: 'Pending Affiliate Signups',
        content: <PendingAffiliateSignups />
      },
      quin_street_placements: {
        section: 'Set Up',
        displayName: 'Quinstreet Placements',
        content: <QuinStreetPlacements />,
        filters: QuinStreetPlacementsFilters(),
        actionItems: [
          {
            drawerTitle: 'Add Placement',
            content: (toggleDrawer: unknown) => (
              <QuinStreetEditPlacement toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      ever_quote_sources: {
        section: 'Set Up',
        displayName: 'EverQuote Sources',
        content: <EverQuoteSources />,
        filters: EverQuoteSourceFilters(),
        actionItems: [
          {
            drawerTitle: 'Add Source',
            content: (toggleDrawer: unknown) => (
              <EverQuoteEdit toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      media_alpha_placements: {
        section: 'Set Up',
        displayName: 'Media Alpha Placements',
        content: <MediaAlphaPlacements />,
        filters: MediaAlphaPlacementsFilters(),
        actionItems: [
          {
            drawerTitle: 'Add Placement',
            content: (toggleDrawer: unknown) => (
              <EditPlacement toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      transparently_placements: {
        section: 'Set Up',
        displayName: 'Transparently Placements',
        content: <TransparentlyPlacements />,
        filters: TransparentlyPlacementsFilters(),
        actionItems: [
          {
            drawerTitle: 'Add Placement',
            content: (toggleDrawer: unknown) => (
              <TransparentlyEditPlacement toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      users: {
        section: 'Set Up',
        displayName: 'Users',
        content: <UsersReport />,
        filters: usersFilters(),
        actionItems: [{ drawerTitle: 'Add User', content: <AddUser /> }]
      }
    };
  }

  if (checkAffiliateManager()) {
    return {
      links: {
        section: 'Set Up',
        displayName: 'Links',
        content: <Links />,
        filters: linksReportFilters()
      },
      affiliate_list: {
        section: 'Set Up',
        displayName: 'Affiliate List',
        content: <AffiliateList />,
        actionItems: [
          {
            drawerTitle: 'Add Affiliate',
            content: (toggleDrawer: unknown) => (
              <AddAffiliate toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      site_pixels: {
        section: 'Set Up',
        displayName: 'Pixels',
        content: <AffiliatePixels />,
        filters: affiliatePixelsFilters(),
        actionItems: [
          {
            drawerTitle: 'Add Pixel',
            content: (toggleDrawer: unknown) => (
              <AddPixel toggleDrawer={toggleDrawer} />
            )
          }
        ]
      },
      pending_affiliate_signups: {
        section: 'Set Up',
        displayName: 'Pending Affiliate Signups',
        content: <PendingAffiliateSignups />
      }
    };
  }

  if (checkAffiliate()) {
    return setupListAffExternal;
  }
  return {};
};

export const sections = () => {
  if (checkAdmin() || checkTech()) {
    if (checkOPS()) {
      return [false, 'Affiliates', 'Buyers', 'Sites', 'Misc'];
    }

    return [false, 'Affiliates', 'Agents', 'Buyers', 'Sites', 'Misc'];
  }

  if (
    checkSuperAffiliate() ||
    checkIsRemarketing() ||
    checkAmb() ||
    checkAffiliate() ||
    checkAffiliateManager() ||
    checkPowerUser()
  ) {
    return [
      false,
      'Set Up',
      (checkSuperAffiliate() || checkIsRemarketing() || checkAmb()) && 'Sites'
    ];
  }

  return [];
};
